import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';

const CompanyInfoTab = ({ fieldPrefix }) => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Company details</h2>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}email`}
        type="text"
        label="Email"
        placeholder="Email"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}first_name`}
        type="text"
        label="Company name"
        placeholder="Company name"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}phone_number`}
        data-type="phone_number"
        type="text"
        label="Phone number"
        placeholder="+1 (XXX) XXX-XXXX"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}description_of_services`}
        type="text"
        label="Intent/Purpose of Business"
        placeholder="Intent/Purpose of Business"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}jurisdictions_of_business_activity`}
        type="text"
        label="Where Does Business Operate"
        placeholder="Where Does Business Operate"
        required
      />
    </Form.Row>
  </>
);

CompanyInfoTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

CompanyInfoTab.defaultProps = {
  fieldPrefix: ''
};

export default CompanyInfoTab;
