import React, { useState } from 'react';

const Personal = () => {
  const [show, toggleShow] = useState(false);
  return (
    <div>
      <p>
        <strong>PERSONAL DOCUMENT FOR THE CONTROLLER</strong>
        <br />
        <a href="#" onClick={() => toggleShow(!show)}>{show ? 'hide more' : 'show more'}</a>
      </p>
      {show ? (
        <>
          <p><strong>US persons: </strong>
          A color scanned photo of the Controller&#39;s identifying document can be specified as documentType:
          license (state issued driver&#39;s license), or idCard (U.S. government-issued photo id card).</p>

          <p>Supported Document Examples:</p>

          <ul>
            <li>Non-expired State Issued Driver’s License/Identification Card</li>
            <li>Non-expired US Passport</li>
            <li>Federal Employment Authorization Card</li>
            <li>US Visa</li>
          </ul>

          <p>Unsupported Document Examples:</p>

          <ul>
            <li>Military IDs</li>
            <li>Expired government-issued IDs</li>
          </ul>

          <p><strong>Non-US persons: </strong> A color scanned photo of the Controller’s identifying document can be specified as
          documentType: passport. Examples include:</p>

          <ul><li>Non-expired Foreign Passport Note: Foreign Passports are only accepted when the individual does not have
          an ITIN or SSN and the user must alternatively enter the Passport number.</li></ul>
        </>
      ) : null}
    </div>
  );
};

export default Personal;
