import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';

const DepositForm = ({ disabled, ...rest }) => {
	const [openModal, setOpenModal] = useState(false);
	const handleClick = () => setOpenModal(true);
	const handleCancel = () => setOpenModal(false);

	return (
    <>
      <button
        className="btn form-btn-fill sub-accounts-list__item-btn"
        onClick={handleClick}
        disabled={disabled}
      >
        Deposit
      </button>
      {disabled ? null : <Modal show={openModal} onCancel={handleCancel} {...rest} />}
    </>
  );

};

DepositForm.propTypes = {
	disabled: PropTypes.bool,
};
DepositForm.defaultProps = {
  disabled: false
};

export default DepositForm;
