import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';
import FileField from 'components/helpers/FileField';
import CustomDatePicker from 'components/helpers/CustomDatePicker';

import {
  USA_STATES,
  COUNTRIES,
} from 'globals/constants.js.erb';

const ACCEPTED_FORMATS = "image/png, image/jpeg, image/jpg, application/pdf";
const ATTACHMENT_TYPE = "Attachments::IdentityDocument";

const VerificationTab = ({ fieldPrefix, localValues }) => {
  const renderState = () => {
    if (localValues.tax_country === 'US') {
      return (
        <CustomSelect
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          label="State"
          name={`${fieldPrefix}tax_state`}
          placeholder="State"
          options={USA_STATES}
          required
        />
      );
    }
    return null;
  };

  const renderRegionOrState = () => {
    const attrs = {
      formGroupProps: { as: Col, sm: 6 },
      name: `${fieldPrefix}region_of_formation`,
      placeholder: 'Region of formation',
      label: 'Region of formation',
      required: true
    };
    if (localValues.tax_country === 'US') {
      return <CustomSelect options={USA_STATES} {...attrs} />;
    } else {
      return <TextField type="text" {...attrs} />;
    }
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Company verification details</h2>

      <Form.Row>
        <CustomSelect
          formGroupProps={{ as: Col, md: 6 }}
          label="Tax country"
          name={`${fieldPrefix}tax_country`}
          placeholder="Tax country"
          options={COUNTRIES}
          required
        />
        {renderState()}
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}ssn`}
          type="text"
          label="Entity Registration Number"
          placeholder="Entity Registration Number"
          required
        />
      </Form.Row>

      <Form.Row>
        <Col md={6}>
          <CustomDatePicker
            name={`${fieldPrefix}date_of_birth`}
            placeholder="Date of incorporation"
            wrapperClassName="d-block"
            maxDate={new Date()}
            label="Date of incorporation"
            required
          />
        </Col>
      </Form.Row>
      <Form.Row>{renderRegionOrState()}</Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}identity_document`}
          relationName="identity_document"
          label="Proof of Entity Formation"
          type={ATTACHMENT_TYPE}
          accept={ACCEPTED_FORMATS}
          required
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}proof_of_address`}
          relationName="proof_of_address"
          label={
            <Form.Label
              className="flex-label"
              title="Must show proof of company in {region-of-formation} by providing a mail item within the last 90
                     days that lists the company and the address provided."
              required
            >
              <span className="form-label__text">Proof of Address &nbsp;<abbr>*</abbr></span>
            </Form.Label>
          }
          accept={ACCEPTED_FORMATS}
          type={ATTACHMENT_TYPE}
          required
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}company_documents`}
          relationName="company_documents"
          label="Company documents"
          accept={ACCEPTED_FORMATS}
          type={ATTACHMENT_TYPE}
          multiple
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Text className="text-muted">
            These documents(s) must show:
            <br />
            1. A copy of the company`s official formation or organizational document,
            such as the articles of incorporation, certificate of formation, partnership agreement,
            by-laws, operation agreement or trust instrument, as applicable
            <br />
            2. Proof of the beneficial ownership of the company evidencing those entities or individuals
            owning 25% or more, such as an operating agreement, partnership agreement,
            share registry, capitalization table, or Schedule K1
          </Form.Text>
        </Form.Group>
      </Form.Row>
    </>
  );
};

VerificationTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

VerificationTab.defaultProps = {
  fieldPrefix: ''
};

export default VerificationTab;
