import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';

const NeighborhoodTab = () => {
  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Neighborhood</h2>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="schools"
          type="text"
          label="Nearby schools"
          placeholder="Schools"
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="crime_rates"
          type="text"
          label="Crime rates"
          placeholder="Rates"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="air_quality"
          type="text"
          label="Air quality"
          placeholder="Air quality"
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="attractions"
          type="text"
          label="Attractions"
          placeholder="Attractions"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="parks"
          type="text"
          label="Parks"
          placeholder="Parks"
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="amusement"
          type="text"
          label="Amusement"
          placeholder="Amusement"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="cultural_sites"
          type="text"
          label="Cultural sites"
          placeholder="Cultural sites"
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="train"
          type="text"
          label="Train"
          placeholder="Train"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="subway"
          type="text"
          label="Subway / Rail"
          placeholder="Subway"
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="bus"
          type="text"
          label="Bus"
          placeholder="Bus"
        />
      </Form.Row>
    </>
  );
};

export default NeighborhoodTab;
