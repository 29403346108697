import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { api as axios } from 'api/instances';
import { setIsLoading } from 'stores/global-store';
import FundsTransferList from '../funds-transfers';

const TransfersTab = ({ links: { funds_transfers: fundsTransfersLink } }) => {
  if (!fundsTransfersLink) return null;

  const [fundsTransfers, setFundsTransfers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios.get(fundsTransfersLink).then(({ data }) => {
      setFundsTransfers(data);
    }).finally(() => setIsLoading(false));
  }, []);

  return (
    <div className="sub-account-details__base-info">
      <h2 className="sub-account-details__base-info__title">Funds Transfers</h2>
      {fundsTransfers.length ? fundsTransfers.map((funds) => (
        <FundsTransferList key={funds.id} funds={funds} />
      )) : (
        'No funds transfers yet'
      )}
    </div>
  );
};

TransfersTab.propTypes = {
  full_name: PropTypes.string.isRequired,
  links: PropTypes.shape({
    funds_transfers: PropTypes.string,
  }).isRequired,
};

export default TransfersTab;
