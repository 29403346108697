import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['listingUsers'];

  handleChangePrivate(data) {
    if (data.target.value === 'true') {
      this.listingUsersTarget.classList.remove('hidden');
    } else {
      this.listingUsersTarget.classList.add('hidden');
    }
  }
}
