import { api } from "./instances";

export const helpers = {
  getAdminSubAccounts: (query) => api.get(`/admin/sub_accounts.json`, { params:  { q: query } }),
  putAdminProperty: (id, payload) => api.patch(`/admin/properties/${id}.json`, payload),
  getSubAccounts: (query) => api.get(`/profile/sub_accounts.json`, { params:  { q: query } }),
  postProperty: (id, payload) => {
    if (id) {
      return api.patch(`/profile/properties/${id}.json`, payload);
    } else {
      return api.post(`/profile/properties.json`, payload);
    }
  },
  postSubAccount: (id, payload) => {
    if (id) {
      return api.patch(`/profile/sub_accounts/${id}.json`, payload);
    } else {
      return api.post(`/profile/sub_accounts.json`, payload);
    }
  },
  postSubAccountsBeneficialOwners: (subAccountLink, id, payload) => {
    if (id) {
      return api.patch(`${subAccountLink}/beneficial_owners/${id}.json`, payload);
    } else {
      return api.post(`${subAccountLink}/beneficial_owners.json`, payload);
    }
  },
  postBankAccount: (subAccountId, payload) => {
    return api.post(`/profile/sub_accounts/${subAccountId}/bank_account.json`, payload);
  },
  patchBankAccount: (subAccountId, payload) => {
    return api.patch(`/profile/sub_accounts/${subAccountId}/bank_account.json`, payload);
  },
  createDeposit: (subAccountId, payload) =>
    api.post(`/profile/sub_accounts/${subAccountId}/deposits.json`, payload),
  createWithdraw: (subAccountId, payload) =>
    api.post(`/profile/sub_accounts/${subAccountId}/withdraws.json`, payload),
  postPtSubAccount: (id, payload) => {
    if (id) {
      return api.patch(`/profile/pt_sub_accounts/${id}.json`, payload);
    } else {
      return api.post(`/profile/pt_sub_accounts.json`, payload);
    }
  },
  postPtContact: (subAccountId, id, payload) => {
    if (id) {
      return api.patch(`/profile/pt_sub_accounts/${subAccountId}/contacts/${id}.json`, payload);
    } else {
      return api.post(`/profile/pt_sub_accounts/${subAccountId}/contacts.json`, payload);
    }
  },
  postPtBankAccount: (subAccountId, id, payload) => {
    if (id) {
      return api.patch(`/profile/pt_sub_accounts/${subAccountId}/bank_account.json`, payload);
    } else {
      return api.post(`/profile/pt_sub_accounts/${subAccountId}/bank_account.json`, payload);
    }
  },
  getPrimeTrustAgreementPreview: (payload) => {
    return api.post(`/profile/pt_sub_accounts/agreement_previews.json`, payload);
  },
  previewPSA: (payload) => {
    return api.post(`/properties/preview/purchase_and_sale_agreement.pdf`, payload, {
      responseType: 'blob',
    });
  },
  previewEscrowAgreement: (payload) => {
    return api.post(`/properties/preview/escrow_agreement.pdf`, payload, {
      responseType: 'blob',
    });
  },
  previewSellerAffidavit: (payload) => {
    return api.post(`/properties/preview/seller_affidavit.pdf`, payload, {
      responseType: 'blob',
    });
  },
  uploadAttachment: payload => {
    const formData = new FormData;
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });
    return api.post(`/attachments.json`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 60000
    });
  },
  deleteAttachment: id => api.delete(`/attachments/${id}.json`),
  validateContact: (payload) => {
    return api.post(`/contacts/validate.json`, payload);
  },
  buildContact: (payload) => api.post(`/contacts/build.json`, payload),
  createPlaidToken: () => api.post('/plaid_link'),
  createDwollaAccessToken: () => api.post('/dwolla_access_token'),
};

export default helpers;
