function applyPhoneMask(value) {
  let x = value.replace(/\D/g, '').match(/(1{0,1})(\d{0,3})(\d{0,3})(\d{0,4})/);
  let formatted = '';
  if(!x[3]) {
    formatted = `+1 (${x[2]}` + (!x[3] ? '' : ')');
  }
  else {
    formatted = '+1 (' + x[2] + ') ' +
                x[3] +
                (!x[4] ? '' : ' - ' + x[4]);
  }
  return formatted;
};

export default applyPhoneMask;
