import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';

import {
  BANK_ACCOUNT_CHECK_TYPES,
  BANK_ACCOUNT_TYPES,
} from 'globals/constants.js.erb';

const UsBankForm = () => {
  return (
    <>
      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="name"
          type="text"
          label="Bank account name"
          placeholder="Bank account name"
          required
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="iban"
          type="text"
          label="Bank account number"
          format={RegExp(/\d/g)}
          placeholder="Bank account number"
          required
        />
      </Form.Row>

      <Form.Row>
        <CustomSelect
          label="ACH check type"
          name="check_type"
          options={BANK_ACCOUNT_CHECK_TYPES}
          formGroupProps={{ as: Col, md: 6 }}
          required
        />
        <CustomSelect
          label="Bank account type"
          name="account_type"
          options={BANK_ACCOUNT_TYPES}
          formGroupProps={{ as: Col, md: 6 }}
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="routing"
          type="text"
          label="Routing number"
          placeholder="Routing number"
          format={RegExp(/\d/g)}
          required
        />
      </Form.Row>
    </>
  );
};

export default UsBankForm;
