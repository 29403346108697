import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';

const CompanyInfoTab = ({ fieldPrefix }) => {
  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Company details</h2>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}business_name`}
          type="text"
          label="Registered business name"
          placeholder="Business name"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}ein`}
          type="text"
          label="EIN (Employer Identification Number)"
          placeholder="EIN"
          required
        />
      </Form.Row>
    </>
  );
};

CompanyInfoTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

CompanyInfoTab.defaultProps = {
  fieldPrefix: ''
};

export default CompanyInfoTab;
