document.onkeydown = function (e) {
  const carousel = document.querySelector('.carousel_modal.show');
  const leftArrowCode = 37;
  const rightArrowCode = 39;
  if (carousel) {
    if (e.keyCode === rightArrowCode) {
      carousel.querySelector('.carousel-control-next').click();
    } else if (e.keyCode === leftArrowCode) {
      carousel.querySelector('.carousel-control-prev').click();
    }

    e.preventDefault();
  };
};
