import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { api as axios } from 'api/instances';
import api from 'api';
import classnames from 'classnames';

import PlaidLink from 'components/helpers/PlaidLink';
import GlobalStore from 'stores/global-store';

import css from './index.module.scss';

const BankAccountLink = ({ link, buttonClass }) => {
  const [token, setToken] = useState(null);

  const handleClick = () => {
    GlobalStore.update(s => { s.isLoading = true; });
    api.createPlaidToken()
      .then(({ data }) => setToken(data.token))
      .catch(({ response }) => {
        // eslint-disable-next-line
        console.error(response);
        handleExit();
      });
  };

  const handleSuccess = (public_token, metadata) => {
    GlobalStore.update(s => { s.isLoading = true; });
    axios
      .post(link, { public_token, metadata })
      .finally(() => window.location.reload());
  };

  const handleExit = () => {
    GlobalStore.update((s) => { s.isLoading = false; });
    setToken(null);
  };

  return (
    <>
      <button
        className={classnames('btn form-btn-fill sub-accounts-list__item-btn', css.button, buttonClass)}
        onClick={handleClick}
        disabled={!link}>
        Link bank account
      </button>
      {token ? (
        <PlaidLink
          token={token}
          onSuccess={handleSuccess}
          onExit={handleExit}
          onReady={() => GlobalStore.update(s => { s.isLoading = false; })
          }
        />
      ) : null}
    </>
  );
};

BankAccountLink.propTypes = {
  link: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.oneOf([null])
	]),
  buttonClass: PropTypes.string
};

BankAccountLink.defaultProps = {
  link: null,
  buttonClass: '',
};

export default BankAccountLink;
