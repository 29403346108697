import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { format } from 'date-fns';

import { DATE_FORMAT } from 'globals/constants.js.erb';
// import VerificationDocs from 'components/dwolla-v2/verification-docs';

import sprite from 'images/icons/sprite.svg';

const ContactCard = ({ contact, onEditClick }) => {
  const renderEditButton = () => {
    // if(contact.status === 'document') return <VerificationDocs resource_link={contact.xid} token="" />;
    if(contact.identity_confirmed || contact.status === 'approved') return null;

    const className = "row btn btn-sm steps_form__tabs__beneficial_owners-edit flex-shrink-0 ml-3";
    const children = [
      <span key="text">Edit details</span>,
      <svg key="icon" width="16px" height="16px" className="ml-2">
        <use xlinkHref={`${sprite}#pencil`} />
      </svg>
    ];

    return (
      <button
        type="button"
        onClick={onEditClick}
        className={className}
      >
        {children}
      </button>
    );
  };

  const name = [contact.first_name, contact.middle_name, contact.last_name].filter(i => i).join(' ');
  const date_of_birth = contact.date_of_birth ? format(Date.parse(contact.date_of_birth), DATE_FORMAT) : null;
  const fullName = [name, date_of_birth].filter(i => i).join(', ');
  const address = [
    contact.street, contact.city, contact.region, contact.country, contact.postal_code
  ].filter(i => i).join(', ');

  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={`contact-${contact.id || contact.uuid}`}>
        <div className="d-flex justify-content-between">
          <div>
            <div className="beneficial-owners__label">{contact.label}</div>
            <div className="beneficial-owners__name">
              {name}
            </div>
          </div>
          <span className={`beneficial-owners__status beneficial-owners__status--${contact.status}`}>
            {contact.status_label}
          </span>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={`contact-${contact.id || contact.uuid}`}>
        <Card.Body className="d-flex flex-row justify-content-between align-items-center">
          <div>
            <div>{fullName}</div>
            <div>{address}</div>
          </div>
          {renderEditButton()}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

ContactCard.propTypes = {
  onEditClick: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    identity_confirmed: PropTypes.bool,
    status_label: PropTypes.string,
    uuid: PropTypes.string,
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postal_code: PropTypes.string,
    date_of_birth: PropTypes.string,
    label: PropTypes.string,
    xid: PropTypes.string,
  }).isRequired,
};

ContactCard.defaultProps = {
};

export default ContactCard;
