import React, { useState } from 'react';
import PropTypes from 'prop-types';
import sprite from 'images/icons/sprite.svg';
import Modal from './modal';

const VerificationDocs = ({ onSuccess, ...props}) => {
  const [openModal, setOpenModal] = useState(false);
  const handleCancel = () => setOpenModal(false);
  const handleSuccess = () => onSuccess && onSuccess();

  return (
    <>
      <div
        className="btn btn-sm sub-accounts-list__item-edit"
        onClick={() => setOpenModal(true)}>
        <span key="text">Upload Documents</span>
        <svg key="icon" width="16px" height="16px" className="ml-2">
          <use xlinkHref={`${sprite}#photo`} />
        </svg>
      </div>
      <Modal show={openModal} onCancel={handleCancel} onSuccess={handleSuccess} {...props} />
    </>
  );
};

VerificationDocs.propTypes = {
  onSuccess: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.oneOf([null])
	]),
};

VerificationDocs.defaultProps = {
  onSuccess: null
};

export default VerificationDocs;
