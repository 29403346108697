import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';
import CheckBox from 'components/helpers/CheckBox';

import {
  COUNTRIES,
} from 'globals/constants.js.erb';

const VerificationTab = ({ fieldPrefix, localValues }) => {
  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">ID verification details</h2>

      {localValues.country === 'US' ? (
        <Form.Row>
          <TextField
            formGroupProps={{ as: Col, md: 6 }}
            name={`${fieldPrefix}ssn`}
            type="text"
            label="Tax ID number/SSN"
            placeholder="Tax ID number/SSN"
            required
          />
        </Form.Row>
      ) : (
        <>
          <Form.Row>
            <TextField
              formGroupProps={{ as: Col, md: 6 }}
              name={`${fieldPrefix}passport_number`}
              type="text"
              label="Passport number"
              placeholder="Passport number"
              required
            />
          </Form.Row>
          <Form.Row>
            <CustomSelect
              formGroupProps={{ as: Col, md: 6 }}
              label="Passport country"
              name={`${fieldPrefix}passport_country`}
              placeholder="Passport country"
              options={COUNTRIES}
              required
            />
          </Form.Row>
        </>
      )}
      <CheckBox
        name={`${fieldPrefix}certified`}
        label="Certify provided details"
        helpText="By checking the checkbox You certify that provided information is correct."
      />
    </>
  );
};

VerificationTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

VerificationTab.defaultProps = {
  fieldPrefix: ''
};

export default VerificationTab;
