import React from 'react';
import PropTypes from 'prop-types';

export const Beneficial = ({ contact }) => {
  const {
    full_name,
    label,
    street,
    city,
    region,
    country,
    postal_code,
    links,
  } = contact;
  const { edit, delete: deleteAccount } = links || {};

  return (
    <>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Name</div>
        <div className="sub-account-details__base-info__field-value">{full_name}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Title</div>
        <div className="sub-account-details__base-info__field-value">{label}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Address</div>
        <div className="sub-account-details__base-info__field-value">{street}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">City</div>
        <div className="sub-account-details__base-info__field-value">{city}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">State</div>
        <div className="sub-account-details__base-info__field-value">{region}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Country</div>
        <div className="sub-account-details__base-info__field-value">{country}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Postal Code</div>
        <div className="sub-account-details__base-info__field-value">{postal_code}</div>
      </div>
      <div className="sub-account-details__base-info__buttons d-flex">
        {edit ? (
          <div className="align-items-start mr-3">
            <a
              className="btn form-btn-outline sub-accounts-list__item-btn"
              href={edit}>
              Edit
            </a>
          </div>
        ) : null}
        {deleteAccount ? (
          <div className="align-items-start">
            <a
              className="btn form-btn-outline sub-accounts-list__item-delete"
              href={deleteAccount}>
              Remove
            </a>
          </div>
        ) : null}
      </div>
    </>
  );
};

Beneficial.propTypes = {
  contact: PropTypes.shape({
    full_name: PropTypes.string,
    label: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    country: PropTypes.string,
    postal_code: PropTypes.string,
    links: PropTypes.object,
  }).isRequired,
};
