import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import SubAccountCard from './SubAccountCard';
import Spinner from 'components/helpers/Spinner';
import api from 'api';

const SubAccountList = () => {
	const [list, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		api.getSubAccounts().then((res) => {
			setList(res.data);
		}).finally(() => setIsLoading(false));
	}, []);

	return (
		<Row className="page-wrapper">
			<div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
			<Col className="col-xl-8 col-lg-10 col-md-10 mx-0 mx-md-auto">
				<Spinner show={isLoading} />
				<div className="mt-5 mb-3 page-title">
					<h1>My property accounts</h1>
					<a className="btn form-btn-outline" href="/profile/sub_accounts/new">
						+ Add property account
					</a>
				</div>
				{list.map((account) => (
					<SubAccountCard key={account.id} account={account} />
				))}
			</Col>
		</Row>
	);
};

export default SubAccountList;
