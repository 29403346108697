import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'globals/constants.js.erb';

export const Customer = ({ owner_contact, accountId, type }) => {
  const { created_at } = owner_contact;

  return (
    <div className="sub-account-details__base-info">
      <h2 className="sub-account-details__base-info__title">Customer</h2>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Account type</div>
        <div className="sub-account-details__base-info__field-value">{type === 'company' ? 'LLC' : 'Personal'}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Customer ID</div>
        <div className="sub-account-details__base-info__field-value">{accountId}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Created</div>
        <div className="sub-account-details__base-info__field-value">{format(Date.parse(created_at), `${DATE_FORMAT} 'at' p`)}</div>
      </div>
    </div>
  );
};

Customer.propTypes = {
  accountId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  owner_contact: PropTypes.shape({
    created_at: PropTypes.string
  }).isRequired,
};
