import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';

import useBeforeUnload from 'components/helpers/useBeforeUnload';
import { stepsFor, componentsFor } from './tabs';
import api from 'api';
import TextField from 'components/helpers/TextField';
import StepsForm from './steps-form';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const STEPS = {
  natural_person: ['personal', 'address', 'verification'],
  company: ['companyInfo', 'address', 'companyVerification', 'relatedContacts'],
  approved_company: ['relatedContacts']
};

const PtContactForm = ({ contact, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);
  const { steps, components } = useMemo(() => {
    let formType = contact.attributes.contact_type;
    if (contact.attributes.contact_type === 'company' && contact.attributes?.identity_confirmed) {
      formType = 'approved_company';
    }

    return {
      steps: stepsFor(STEPS[formType]),
      components: componentsFor(STEPS[formType])
    };
  }, [contact]);

  const [tabsList, setVisited] = useState(contact.id ?
    steps.map(i => i.target) : steps.slice(0, 1).map(i => i.target));
  const isSubmitVisible = useMemo(() => tabsList.length === steps.map(i => i.target).length, [tabsList, steps]);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const formHeader = useMemo(() => {
    const action = contact.id ? 'Edit' : 'Add';
    let title, relation = '';
    if (contact.attributes.related_to) {
      const preposition = contact.id ? 'of' : 'to';
      title = contact.type === 'company' ?
        'Beneficial entity' : 'Beneficial Owner (Individual) or Control Person';
      relation = `${preposition} ${contact.attributes.related_to}`;
    } else {
      title = contact.type === 'company' ? 'Company' : 'Individulal';
      relation = 'sub-account';
  }
    return `${action} ${title} ${relation}`;
  }, [contact.id, contact.contact_type]);

  const setVisitedTab = e => {
    const visited = e.target.dataset.visited;
    tabsList.push(visited);
    setVisited([...tabsList]);
  };

  const isVisited = (tab) => {
    if (contact.id) { return true; }

    return tabsList.some(item => item === tab);
  };

  const submitForm = () => {
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postPtContact(contact.attributes.sub_account_id, contact.id, serialize(values)).
      then(() => window.location.href = '/profile/pt_sub_accounts').
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm();
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    if (data.errors) {
      const tabs = steps.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const handleCancel = () => window.location.href = '/profile/pt_sub_accounts';

  const serialize = () => {
    return {
      contact: {
        ...values,
        status: 'pending',
        beneficial_owners: null,
        beneficial_owners_attributes: values.beneficial_owners?.filter(i => i.changed),
      },
    };
  };

  return (
    <div className="steps_form">
      {isLoading && (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">{formHeader}</h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {steps.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link',
                  isVisited(item.target) ? 'visited' : '',
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              contact.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <Row>
              <TextField
                formGroupProps={{ as: Col, md: 6 }}
                name="label"
                type="text"
                label="Title"
                placeholder="Title"
                required
              />
            </Row>
            <StepsForm
              components={components}
              onChangeTab={setVisitedTab}
              visitedTabs={tabsList}
              onCancel={handleCancel}
              localValues={values}
            />
          </div>
          {isSubmitVisible && (
            <>
              {contact.id ? (
                <div className="form-actions-edit py-4 row">
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-actions mt-4">
                  <div className="d-sm-inline-block mb-3 mr-3">
                    <button
                      className="btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="d-sm-inline-block mb-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

PtContactForm.propTypes = {
  contact: PropTypes.object,
};
PtContactForm.defaultProps = {
  contact: {}
};

export default withFormik({
  mapPropsToValues: ({ contact }) => {
    return Object.entries({ ...contact.attributes }).
      reduce((acc, [key, value]) => {
        if (value instanceof Object && value instanceof Array === false) {
          acc[key] = {};
          Object.entries(value).forEach(([k, v]) => acc[key][k] = v === null ? '' : v);
          return acc;
        }
        acc[key] = value === null ? '' : value;
        return acc;
      }, {});
  },
})(PtContactForm);
