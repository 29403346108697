import { setCookie } from 'src/utils/cookies-manager';

document.addEventListener('DOMContentLoaded', convertTime);

function convertTime() {
  const dateFormat = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  const elements = document.getElementsByClassName('js--convert-time');
  for (let element of elements) {
    const localDateTime = new Date(element.textContent + ' UTC');
    const formatedTime = dateFormat.format(localDateTime);
    element.textContent = formatedTime;
  }
};

setCookie('offset', -(new Date().getTimezoneOffset()));
