import React from 'react';
import PropTypes from 'prop-types';

import BankAccountEdit from '../../dwolla-v2/bank-account-edit';

const BankAccountTab = ({ bank_account, edit_bank_account }) => {
  const { account_type, bank_name, iban, account_type_label } = bank_account;

  return (
    <div className="sub-account-details__base-info">
      <h2 className="sub-account-details__base-info__title">Linked bank account</h2>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Country</div>
        <div className="sub-account-details__base-info__field-value">United States</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Bank acc. name</div>
        <div className="sub-account-details__base-info__field-value">{bank_name}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Bank acc. number</div>
        <div className="sub-account-details__base-info__field-value">{iban}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">ACH check type</div>
        <div className="sub-account-details__base-info__field-value">{account_type_label}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Bank acc. type</div>
        <div className="sub-account-details__base-info__field-value">{account_type}</div>
      </div>
      <div className="sub-account-details__base-info__buttons d-flex mt-4">
        {edit_bank_account ? (
          <div className="align-items-start mr-3">
            <BankAccountEdit link={edit_bank_account} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

BankAccountTab.propTypes = {
  edit_bank_account:PropTypes.string,
  bank_account: PropTypes.shape({
    account_type: PropTypes.string,
    bank_name: PropTypes.string,
    iban: PropTypes.string,
    account_type_label: PropTypes.string,
  }).isRequired
};

export default BankAccountTab;
