import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';

import CheckBox from 'components/helpers/CheckBox';
import TermsAndConditionsLabel from 'components/helpers/TermsAndConditionsLabel';

import CustomSelect from 'components/helpers/CustomSelect';
import UsBankForm from './UsBankForm';
import InternationalBankForm from './InternationalBankForm';
import useBeforeUnload from 'components/helpers/useBeforeUnload';
import api from 'api';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const BANK_COUNTRIES = [
  { title: 'US', value: false }, { title: 'International', value: true }
];

const PtBankAccountForm = ({ bank_account, ...formikBag }) => {
  const { values, dirty, setStatus, setSubmitting, isSubmitting, setFieldValue } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const isInternational = () => {
    return ['true', true].includes(values.international);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postPtBankAccount(bank_account.attributes.sub_account_id, bank_account.id, serialize(values)).
      then(() => window.location.href = '/profile/pt_sub_accounts').
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    setFieldValue('terms', false);
    if (data.errors) {
      GlobalStore.update(s => { s.apiErrors = data.errors; });
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
    }
    window.scrollTo(0, 0);
  };

  const serialize = () => {
    let correctedValues;

    if (isInternational()) {
      correctedValues = {
        routing: '',
        check_type: '',
        account_type: '',
        intermediary_address_attributes: values.intermediary_address,
      };
    } else {
      correctedValues = {
        bank_name: '',
        swift: '',
        intermediary_address: '',
        intermediary_bank_iban: '',
        intermediary_bank_name: '',
        intermediary_bank_swift_code: '',
      };
      if (values.intermediary_address.id !== '') {
        correctedValues.intermediary_address_attributes = {
          id: values.intermediary_address.id,
          _destroy: true,
        };
      }
    }
    return {
      bank_account: {
        ...values,
        ...correctedValues,
      },
    };
  };

  const handleSelectForm = event => {
    GlobalStore.update(s => { s.apiErrors = {}; });
    setFieldValue('international', event.target.value);
  };

  const renderBankForm = () => {
    if (values.international === '') return null;

    return isInternational() ? <InternationalBankForm setFieldValue={setFieldValue}/> : <UsBankForm />;
  };

  return (
    <Form className="position-relative steps_form" noValidate onSubmit={onSubmit}>
      {isLoading &&
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-3">
            {bank_account.id ? 'Edit bank account' : 'Link bank account'}
          </h1>
          <h2 className="h-secondary-font pt-2">
            Bank account details
          </h2>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <div
            className={classnames(
              'steps_form__sections-wrapper',
              bank_account.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <Form.Row>
              <CustomSelect
                label="Bank country"
                name="international"
                options={BANK_COUNTRIES}
                formGroupProps={{ as: Col, md: 6 }}
                onChange={handleSelectForm}
                required
              />
            </Form.Row>
            {renderBankForm()}
            {[true, 'true', false, 'false'].includes(values.international) ? (
              <CheckBox
                name="terms"
                label={<TermsAndConditionsLabel />}
              />
            ) : null}
          </div>
          <div className={'form-actions py-2 row'}>
            <div className={'col-6 col-sm-4 col-lg-3'}>
              <button
                className="btn form-btn-fill form-button"
                type="submit"
                disabled={values.terms !== true || isLoading}
              >
                Save
              </button>
            </div>
            <div className={'col-6 col-sm-4 col-lg-3'}>
              <a className="btn form-btn-outline form-button" href="/profile/pt_sub_accounts">
                Cancel
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PtBankAccountForm.propTypes = {
  bank_account: PropTypes.object,
};
PtBankAccountForm.defaultProps = {
  bank_account: {}
};

export default withFormik({
  mapPropsToValues: ({ bank_account }) => {
    return Object.entries({ ...bank_account.attributes }).
      reduce((acc, [key, value]) => {
        if (value instanceof Object) {
          acc[key] = {};
          Object.entries(value).forEach(([k, v]) => acc[key][k] = v === null ? '' : v);
          return acc;
        }
        acc[key] = value === null ? '' : value;
        return acc;
      }, {
        terms: 0
      });
  },
})(PtBankAccountForm);
