
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import VerificationDocs from '../../dwolla-v2/verification-docs';
import DocumentList from '../documents';
import { api as axios } from 'api/instances';
import { setIsLoading } from 'stores/global-store';


const DocumentsTab = ({ xid, name, link, beneficials }) => {
  const [documents, setDocumentList] = useState({});

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      link && axios.get(link).then((res) => ({ owner: res.data })),
      ...beneficials.map(beneficial =>
        axios.get(beneficial.links.documents).then((res) => ({ [beneficial.id]: res.data }))
      )
    ]).then((values) => {
      const documentList = values.reduce((acc, { value: data }) => ({ ...acc, ...data }), documents);
      setDocumentList(documentList);
    }).finally(() => setIsLoading(false));
  }, []);

  const fetchDocuments = (key, documentLink) => () => {
    setIsLoading(true);
    axios.get(documentLink)
      .then((res) => setDocumentList({ ...documents, [key]: res.data }))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {link ? (
        <div className="sub-account-details__base-info">
          <h2 className="sub-account-details__base-info__title">Owner documents</h2>
          <div className="document-list">
            <div className="document-list__row document-list__row--header">
              <div className="document-list__cell document-list__cell--column-1 pl-0">
                Name
              </div>
              <div className="document-list__cell document-list__cell--column-2 pl-0 d-none d-sm-block">
                Type
              </div>
              <div className="document-list__cell document-list__cell--column-3 pl-0 flex-grow">Comment</div>
              <div className="document-list__cell document-list__cell--column-4 pl-0">Status</div>
            </div>
            <DocumentList name={name} documents={documents.owner} />
          </div>
          <div className="sub-accounts-list__item-message sub-accounts-list__item-message--pending p-2">
            <div className="sub-accounts-list__item-message__text mb-2">
              To verify full_name beneficial ownership please attach photo or
              scans of ID Card.
            </div>
            <VerificationDocs resource_link={xid} onSuccess={fetchDocuments('owner', link)} />
          </div>
        </div>
      ) : null}
      {beneficials.length ? (
        <div className="sub-account-details__base-info">
          <h2 className="sub-account-details__base-info__title">Beneficial Owner documents</h2>
          <div className="document-list">
            <div className="document-list__row document-list__row--header">
              <div className="document-list__cell document-list__cell--column-1 pl-0">
                Name
              </div>
              <div className="document-list__cell document-list__cell--column-2 pl-0 d-none d-sm-block">
                Type
              </div>
              <div className="document-list__cell document-list__cell--column-3 pl-0 flex-grow">Comment</div>
              <div className="document-list__cell document-list__cell--column-4 pl-0">Status</div>
            </div>
            {beneficials.map((beneficial) => (
              <div key={beneficial.id}>
                <DocumentList name={beneficial.full_name} documents={documents[beneficial.id]} />
              </div>
            ))}
            {beneficials.map((beneficial) => (
              <div key={beneficial.id} className="sub-accounts-list__item-message sub-accounts-list__item-message--pending p-2 mb-2">
                <div className="sub-accounts-list__item-message__text mb-2">
                  To verify full_name beneficial ownership please attach photo or
                  scans of ID Card.
                </div>
                <VerificationDocs resource_link={beneficial.xid} onSuccess={fetchDocuments(beneficial.id, beneficial.links.documents)} />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

DocumentsTab.propTypes = {
  xid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.oneOf([null])
	]),
  beneficials: PropTypes.array
};

DocumentsTab.defaultProps = {
  link: null,
  beneficials: []
};

export default DocumentsTab;
