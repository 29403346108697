import React from 'react';

const TermsAndConditionsLabel = () => (
  <>
    <span>I agree to&nbsp;</span>
    <a href="/website-documents/SimKeyLLC_Terms_Conditions.pdf" target="_blank" rel="noreferrer">the Terms & Conditions</a>
    <span>&nbsp;and&nbsp;</span>
    <a href="/website-documents/SimKeyLLC_Privacy_Policy.pdf" target="_blank" rel="noreferrer">Privacy Policy</a>
  </>
);

export default TermsAndConditionsLabel;
