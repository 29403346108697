import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';

const OtherTab = () => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Other</h2>

    <Form.Row>
      <CustomSelect
        label="Mortgage"
        name="mortgage"
        options={[{ title: 'Yes', value: true }, { title: 'No', value: false }]}
        formGroupProps={{ as: Col, md: 6 }}
        required
      />
    </Form.Row>
  </>
);

export default OtherTab;
