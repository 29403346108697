import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';

import RelatedContactModal from '../modals/related-contact-modal';
import { useFormikContext } from 'formik';
import api from 'api';
import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';
import { DATE_FORMAT } from 'globals/constants.js.erb';
import sprite from 'images/icons/sprite.svg';

const RelatedContactsTab = ({ localValues, fieldPrefix }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeContact, setActiveContact] = useState({});
  const { setFieldValue } = useFormikContext();

  const addContact = (contact) => {
    const relatedContacts = localValues.beneficial_owners || [];
    contact.changed = true;
    const index = contact.id ?
      relatedContacts.findIndex(c => c.id === contact.id) : relatedContacts.findIndex(c => c.uuid === contact.uuid);
    if (index >= 0) {
      relatedContacts[index] = contact;
    } else {
      relatedContacts.push(contact);
    }
    setFieldValue(`${fieldPrefix}beneficial_owners`, relatedContacts);
    setOpenModal(false);
    resetApiError(`${fieldPrefix}beneficial_owners`);
  };

  const handleAddContactClick = (e) => openNewForm({ type: e.target.dataset.contactType });

  const openEditForm = (contact) => {
    setActiveContact(contact);
    setOpenModal(true);
  };

  const openNewForm = ({ type }) => {
    resetApiError(`${fieldPrefix}beneficial_owners`);
    GlobalStore.update(s => { s.isLoading = true; });
    api.buildContact({ type }).
      then(({ data: { attributes } }) => {
        setActiveContact({ uuid: uuid(), ...attributes });
        setOpenModal(true);
      }).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
      });
  };

  const resetBaseApiError = () => resetApiError('base');

  const addCompanyButton = () => {
    if (localValues.xid) {
      return (
        <a
          className="btn btn-outline-secondary"
          href={
            `${localValues.sub_account_link}/contacts/new?type=company&related_to=${localValues.id}`
          }
          target="_blank"
          rel="noreferrer"
        >
          Add Beneficial Entity
        </a>
      );
    } else {
      return (
        <button
          className="btn btn-outline-secondary"
          type="button"
          data-contact-type="Contacts::Company"
          onClick={handleAddContactClick}
        >
          Add Beneficial Entity
        </button>
      );
    }
  };

  const addPersonButton = () => {
    if (localValues.xid) {
      return (
        <a
          className="btn btn-outline-secondary"
          href={`${localValues.sub_account_link}/contacts/new?related_to=${localValues.id}`}
          target="_blank"
          rel="noreferrer"
        >
          Add Beneficial Owner (Individual) or Control Person
        </a>
      );
    } else {
      return (
        <button
          className="btn mr-2 btn-outline-secondary"
          type="button"
          data-contact-type="Contacts::NaturalPerson"
          onClick={handleAddContactClick}
        >
          Add Beneficial Owner (Individual) or Control Person
        </button>
      );
    }
  };

  const editContactButton = (contact) => {
    const className = "row btn btn-sm steps_form__tabs__beneficial_owners-edit flex-shrink-0 ml-3";
    const children = [
      <span key="text">Edit details</span>,
      <svg key="icon" width="16px" height="16px" className="ml-2">
        <use xlinkHref={`${sprite}#pencil`} />
      </svg>
    ];

    if (localValues.xid && contact.id) {
      if (contact.identity_confirmed) return null;
      if (!contact.changed && contact.status !== 'rejected') return null;

      return (
        <a
          className={className}
          href={`${localValues.sub_account_link}/contacts/${contact.id}/edit`}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }

    return (
      <button
        type="button"
        onClick={() => openEditForm(contact)}
        className={className}
      >
        {children}
      </button>
    );
  };

  const renderTabError = () => {
    const errorMessage = readApiError(`${fieldPrefix}beneficial_owners`);
    return errorMessage ? (
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    ) : null;
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Beneficial Owners and/or Control Persons</h2>

      <Form.Row className="mb-3">
        <Col md={12}>
          <Accordion>
            {localValues.beneficial_owners?.length ? (
              localValues.beneficial_owners.map((contact, index) => {
                const name = [contact.first_name, contact.middle_name, contact.last_name].filter(i => i).join(' ');
                const date_of_birth = contact.date_of_birth ? format(Date.parse(contact.date_of_birth), DATE_FORMAT) : null;
                const fullName = [name, date_of_birth].filter(i => i).join(', ');
                const address = [
                  contact.street, contact.city, contact.region, contact.country, contact.postal_code
                ].filter(i => i).join(', ');
                return (
                  <Card key={contact.id || `contact-${index}`}>
                    <Accordion.Toggle as={Card.Header} eventKey={`contact-${index}`}>
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="beneficial-owners__label">{contact.label}</div>
                          <div className="beneficial-owners__name">
                            {name}
                          </div>
                        </div>
                        <span className={`beneficial-owners__status beneficial-owners__status--${contact.status}`}>
                          {contact.status_label}
                        </span>
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`contact-${index}`}>
                      <Card.Body className="d-flex flex-row justify-content-between align-items-center">
                        <div>
                          <div>{fullName}</div>
                          <div>{address}</div>
                        </div>
                        {editContactButton(contact)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              })
            ) : null}
          </Accordion>
        </Col>
      </Form.Row>
      {renderTabError()}

      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Text className="text-muted">
            FinCen defines a Beneficial Owner as:
            <br />
            1. Each individualism if any, who, directly or indirectly, owns 25% or more of the equity interests
            of a legal entity customer (i.e., the ownership prong); and
            <br />
            2. A single individual with significant responsibility to control, manage,
            or direct a legal entity customer,
            including an executive officer or senior manager (e.g., a Chief Executive Officer, Chief Financial Officer,
            Managing member, General Partner, President, Vice President, or Treasurer) or any other individual
            who regularly performs similar functions (i.e. the control prong).
          </Form.Text>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Text className="text-muted mb-2">
            If there are no individuals that directly or indirectly own 25% or more of the equity interests,
            at least one control person must be added.
          </Form.Text>
          {addPersonButton()}
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Text className="text-muted mb-2">
            If any entity owns 25% or more, please add them:
          </Form.Text>
          {addCompanyButton()}
        </Form.Group>
      </Form.Row>
      <RelatedContactModal
        contact={activeContact}
        show={openModal}
        onAccept={addContact}
        onEnter={resetBaseApiError}
        onExit={resetBaseApiError}
        onDismiss={() => setOpenModal(false)}
      />
    </>
  );
};

RelatedContactsTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

RelatedContactsTab.defaultProps = {
  fieldPrefix: ''
};

export default RelatedContactsTab;
