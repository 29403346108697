import React from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { useFormikContext } from 'formik';

import TextField from 'components/helpers/TextField';
import CheckBox from 'components/helpers/CheckBox';
import TermsAndConditionsLabel from 'components/helpers/TermsAndConditionsLabel';

import GlobalStore from 'stores/global-store';

const Form = ({ onSubmit, onCancel }) => {
  const { values } = useFormikContext();

  const isLoading = GlobalStore.useState(s => s.isLoading);

  return (
    <BootstrapForm>
      <BootstrapForm.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="amount"
          type="number"
          label="Amount, $"
          placeholder="0.00"
          autoFocus
          required
        />
      </BootstrapForm.Row>

      <BootstrapForm.Row>
        <Col md={10}>
          <CheckBox
            name="terms"
            label={<TermsAndConditionsLabel />}
            helpText="I authorize SimKey to debit my bank account for the amount specified above.
                      I understand that these funds will be withdrawn from my bank account as early as today.
                      I will not dispute this transaction so long as the transaction is executed according to the terms
                      (amount, date, recipient, funding source) I've selected above."
          />
        </Col>
      </BootstrapForm.Row>

      <div className="form-actions mt-4">
        <div className="d-sm-inline-block mb-3 mr-3">
          <button
            className="btn form-btn-fill form-button"
            type="button"
            onClick={onSubmit}
            disabled={values.terms !== true || isLoading}
          >
            Continue
          </button>
        </div>
        <div className="d-sm-inline-block mb-3">
          <button
            className="btn form-btn-outline"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </BootstrapForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default Form;
