import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import ContactForm from '../related-contact-form';

const RelatedContactModal = ({ contact, onDismiss, onAccept, ...rest }) => {
  if (contact.identity_confirmed) return null;

  const handleDismiss = () => {
    if (onDismiss) onDismiss();
  };
  const handleAccept = (contact) => {
    if (onAccept) onAccept(contact);
  };

  return (
    <Modal
      onHide={handleDismiss}
      backdrop="static"
      keyboard={false}
      size="xl"
      scrollable
      {...rest}
    >
      <Modal.Body className="pb-0">
        <ContactForm
          contact={contact}
          onSubmit={handleAccept}
          onCancel={handleDismiss}
        />
      </Modal.Body>
    </Modal>
  );
};


RelatedContactModal.propTypes = {
  onDismiss: PropTypes.func,
  onAccept: PropTypes.func,
  contact: PropTypes.object
};

RelatedContactModal.defaultProps = {
  onDismiss: null,
  onAccept: null,
  contact: {}
};

export default RelatedContactModal;
