require('@rails/ujs').start();
require('channels');
require('bootstrap');
import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
require.context('../images', true);
require.context('../videos', true);

// import "@hotwired/turbo-rails";
import 'react-datepicker/dist/react-datepicker.css';
import '../stylesheets/application';

import 'controllers';
import 'src/utils/disable-links';
import 'src/utils/time-convertor';
import 'src/utils/carousel-navigation';
