import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FileField from 'components/helpers/FileField';

const DocumentsTab = () => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Documents</h2>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="title_doc_file"
        label="Title report"
        required
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="guaranty_file"
        label="Guaranty"
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="warranty_file"
        label="Warranty"
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="insurance_doc_file"
        label="Insurance doc"
      />
    </Form.Row>
  </>
);

export default DocumentsTab;
