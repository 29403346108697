import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';

import useBeforeUnload from 'components/helpers/useBeforeUnload';
import Tabs, { TabLinks } from './tabs';
import CheckBox from 'components/helpers/CheckBox';
import api from 'api';
import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const PropertyForm = ({ property, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting, setFieldValue } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);
  const [tabsList, setVisited] = useState(['general']);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const notAgreedWithTerms = useMemo(() =>
    [values.psa_accepted, values.escrow_agreement_accepted, values.seller_affidavit_accepted].some(
      accepted => accepted !== true),
    [values.psa_accepted, values.escrow_agreement_accepted, values.seller_affidavit_accepted]
  );
  const setVisitedTab = e => {
    const visited = e.target.dataset.visited;
    tabsList.push(visited);
    setVisited([...tabsList]);
  };

  const isVisited = (tab) => {
    if (property.id) { return true; }

    const visited = tabsList.find(item => item === tab);
    return visited;
  };

  const redirectToPath = () => {
    return property.id ? `/properties/${property.id}` : '/profile/properties';
  };

  const handleClickPreviewPsa = (event) => {
    handlePreviewFile(event, api.previewPSA(serialize()));
  };

  const handleClickPreviewEscrowAgreement = (event) => {
    handlePreviewFile(event, api.previewEscrowAgreement(serialize()));
  };

  const handleClickPreviewSellerAffidavit = (event) => {
    handlePreviewFile(event, api.previewSellerAffidavit(serialize()));
  };

  const handlePreviewFile = (event, request) => {
    event.preventDefault();
    GlobalStore.update(s => { s.isLoading = true; });
    request.
      then(({ data }) => {
        const blob = new Blob([data], { type : 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).
      catch(({ response })=> {
        GlobalStore.update(s => { s.apiErrors = { base: [response.statusText] }; });
        window.scrollTo(0, 0);
      }).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const submitForm = () => {
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postProperty(property.id, serialize()).
      then(() => window.location.href = redirectToPath()).
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    submitForm();
  };

  const handleError = ({ response, response: { data } })=> {
    setStatus('inValid');
    setFieldValue('psa_accepted', false);
    setFieldValue('escrow_agreement_accepted', false);
    setFieldValue('seller_affidavit_accepted', false);
    if (data.errors) {
      const tabs = TabLinks.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else if (Object.keys(data.errors).some(key =>
        ['psa_accepted', 'escrow_agreement_accepted', 'seller_affidavit_accepted'].includes(key))) {
        window.scrollTo(0, document.body.scrollHeight);
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const serialize = () => {
    const units = values.asset_type === 'vacant_land' ?
      values.units.map(unit => ({ ...unit, beds:  '' })) : values.units;

    return {
      property: {
        ...values,
        status: ['draft', 'unlisted'].includes(values.status) ? 'pending' : values.status,
        sub_account_id: values.sub_account,
        residential_type: values.asset_type === 'residential' ? values.residential_type : '',
        land_description: values.asset_type === 'vacant_land' ? values.land_description : '',
        built_in: values.asset_type === 'vacant_land' ? '' : values.built_in,
        units_attributes: [...values.units_attributes, ...units],
        total_units: values.asset_type === 'vacant_land' ? values.total_units : values.units.length,
        images: values.images.map((image, i) => ({ ...image, position: i + 1 })),
      }
    };
  };

  const termsInputs = () => (
    <>
      <CheckBox
        name="psa_accepted"
        label={
          <>
            <span>I agree with&nbsp;</span>
            <span className="link" onClick={handleClickPreviewPsa}>Purchase and Sale Agreement</span>
          </>
        }
        helpText="By checking the checkbox you acknowledge that you have read and
                  agree with the terms under the Purchase and Sale Agreement."
      />
      <CheckBox
        name="escrow_agreement_accepted"
        label={
          <>
            <span>I agree with&nbsp;</span>
            <span className="link" onClick={handleClickPreviewEscrowAgreement}>Escrow Agreement</span>
          </>
        }
        helpText="By checking the checkbox you acknowledge that you have read and
                  agree with the terms under the Escrow Agreement."
      />
      <CheckBox
        name="seller_affidavit_accepted"
        label={
          <>
            <span>I agree with&nbsp;</span>
            <span className="link" onClick={handleClickPreviewSellerAffidavit}>Owner-Seller Affidavit</span>
          </>
        }
        helpText="By checking the checkbox you acknowledge that you have read and
                  agree with the terms under the Owner-Seller Affidavit."
      />
    </>
  );

  const renderTabs = (tab, index) => {
    const visited = isVisited(tab);
    if (!visited) return null;

    const ComponentTab = Tabs[tab];
    const nextTab = Object.keys(Tabs)[index + 1];

    return (
      <div
        key={tab}
        id={tab}
        className={classnames(
          'steps_form__section',
          visited ? "visited" : "",
        )}
      >
        <ComponentTab />
        {!nextTab && termsInputs()}
        {!property.id && <div className="form-actions mt-4 row">
          <div className="col-6 col-sm-4 col-lg-3 mb-3">
            {nextTab ? (
              <a
                href={`#${nextTab}`}
                className="btn form-btn-fill form-button"
                data-visited={nextTab}
                onClick={setVisitedTab}
              >
                Next
              </a>
            ) : (
              <button
                className="btn form-btn-fill form-button"
                type="submit"
                title={notAgreedWithTerms ? 'Please agree with the terms' : null}
                disabled={notAgreedWithTerms || isLoading}
              >
                Save
              </button>
            )}
          </div>
          <div className="col-6 col-sm-4 col-lg-3 mb-3">
            <a className="btn form-btn-outline form-button" href="/profile/properties">
              Cancel
            </a>
          </div>
          {!nextTab && <div className="form-actions__info col-12">
            After you add a piece of property the SimKey team will review the details
            before your piece of property can be listed on the platform.
          </div>}
        </div>}
      </div>
    );
  };

  const apiError = readApiError('base');

  return (
    <Form className="position-relative" noValidate onSubmit={onSubmit}>
      {isLoading &&
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="mx-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">Add piece of property</h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {TabLinks.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link',
                  isVisited(item.target) ? "visited" : "",
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              property.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            {Object.keys(Tabs).map(renderTabs)}
          </div>
          {property.id && <div className="form-actions-edit py-4 row">
            <div className="col-6 col-sm-4 col-lg-3">
              <button
                className="btn form-btn-fill form-button"
                type="submit"
                title={notAgreedWithTerms ? 'Please agree with the terms' : null}
                disabled={notAgreedWithTerms || isLoading}
              >
                Save
              </button>
            </div>
            <div className="col-6 col-sm-4 col-lg-3">
              <a
                className="btn form-btn-outline form-button"
                href={redirectToPath()}
              >
                Cancel
              </a>
            </div>
          </div>}
        </Col>
      </Row>
    </Form>
  );
};

PropertyForm.propTypes = {
  property: PropTypes.object,
};
PropertyForm.defaultProps = {
  property: {}
};


export default withFormik({
  mapPropsToValues: ({ property: { attributes } }) => {
    return Object.entries(attributes || {}).reduce((acc, [key, value]) => {
      acc[key] = value === null ? '' : value;
      return acc;
    }, {
      sub_account: attributes.sub_account_id || '',
      units: [{ id: '', beds: '', annual_revenue: '' }],
      units_attributes: [],
      psa_accepted: 0,
      escrow_agreement_accepted: 0,
      seller_affidavit_accepted: 0,
    });
  },
})(PropertyForm);
