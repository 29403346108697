import PersonalTab from './PersonalTab';
import AddressTab from './AddressTab';
import VerificationTab from './VerificationTab';

const TabLinks = [
  {
    target: 'personal',
    title: 'Personal details',
    fields: [
      /\.{0,1}first_name$/,
      /\.{0,1}last_name$/,
      /\.{0,1}email$/,
      /\.{0,1}phone_number$/,
      /\.{0,1}date_of_birth$/,
      /\.{0,1}ssn$/,
    ]
  },
  {
    target: 'address',
    title: 'Address',
    fields: [
      /\.{0,1}street$/,
      /\.{0,1}city$/,
      /\.{0,1}region$/,
      /\.{0,1}postal_code$/,
    ]
  },
  {
    target: 'verification',
    title: 'ID verification details',
    fields: [
      /\.{0,1}ssn$/,
      /\.{0,1}passport_number/,
      /\.{0,1}passport_country/,
      /\.{0,1}certified/,
    ]
  },
];

export function stepsFor(steps = [], prefix = '') {
  let tabLinks = TabLinks.filter(tab => steps.includes(tab.target));
  if (prefix) {
    tabLinks = tabLinks.map(s => ({ ...s, target: `${prefix}${s.target}` }));
  }
  return tabLinks;
}

export function componentsFor(steps = [], prefix = '') {
  const components = TabLinks.reduce((acc, tab) => {
    if (steps.includes(tab.target)) {
      let id = tab.target;
      if (prefix) id = `${prefix}${id}`;
      acc[id] = Tabs[tab.target];
    }
    return acc;
  }, {});
  return components;
}

const Tabs = {
  personal: PersonalTab,
  address: AddressTab,
  verification: VerificationTab,
};

export default Tabs;
