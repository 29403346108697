import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BootstrapForm from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useFormikContext, FieldArray } from 'formik';

import CustomSelect from 'components/helpers/CustomSelect';
import FileField from 'components/helpers/FileField';
import Personal from './regulations/personal';
import Business from './regulations/business';
import Controller from './regulations/controller';
import GlobalStore, { resetApiError } from 'stores/global-store';

const DWOLLA_DOCUMENT_TYPES = [
  { value: 'passport', title: 'Non-expired Foreign Passport' },
  { value: 'license', title: 'State issued driver`s license' },
  { value: 'idCard', title: 'U.S. government-issued photo id card' },
  { value: 'other', title: 'Other' },
];

const ACCEPTED_FORMATS = "image/png, image/jpeg, image/jpg, application/pdf";

const Form = ({ linkName, business, onSubmit, onCancel }) => {
  const { values, setFieldValue } = useFormikContext();
  const handleFileChange = (index) => ({ target }) => {
    resetApiError(`documents.${index}`);
    setFieldValue(`documents[${index}].file`, target.files[0]);
  };

  const errors = GlobalStore.useState(s => s.apiErrors);

  const renderBusinessRegulations = useMemo(() => {
    switch(linkName) {
      case 'verify-business-with-document':
        return <Business />;
      case 'verify-controller-and-business-with-document':
        return <><Controller /><Business /></>;
      default:
        return <Controller />;
    }
  }, [linkName]);

  return (
    <BootstrapForm>
      <Row>
        <Col>
          <BootstrapForm.Text>
            Needed document types:
            {business ? renderBusinessRegulations : <Personal />}
          </BootstrapForm.Text>
        </Col>
      </Row>
      <FieldArray name="documents" render={arrayHelpers => (
         <>
           {values.documents && values.documents.length > 0 ? (
              values.documents.map((document, index) => {
                const errorMessage = errors[`documents.${index}.basic`];
                return (
                  <Row key={index}>
                    <CustomSelect
                      formGroupProps={{ as: Col, md: 6 }}
                      label="Document type"
                      name={`documents.${index}.documentType`}
                      placeholder="Choose"
                      options={DWOLLA_DOCUMENT_TYPES}
                      required
                    />
                    <FileField
                      formGroupProps={{ as: Col, md: 3 }}
                      name={`documents.${index}.file`}
                      accept={ACCEPTED_FORMATS}
                      onChange={handleFileChange(index)}
                      required
                    />
                    <BootstrapForm.Group as={Col} md={3} className="justify-self-start">
                      <Row>
                        {values.documents[index - 1] ? (
                          <Col>
                            <div>&nbsp;</div>
                            <a
                              href="#"
                              className="text-danger"
                              onClick={(e) => { arrayHelpers.remove(index); e.preventDefault(); }}
                            >
                              Remove
                            </a>
                          </Col>
                        ) : null}

                      </Row>

                    </BootstrapForm.Group>
                    {errorMessage ? (
                      <Col xl={12}>
                        <div className="invalid-feedback pb-3">{errorMessage}</div>
                      </Col>
                    ) : null}
                  </Row>
                );
              }
            )
          ) : null}
          <a
            href="#"
            onClick={(e) => { arrayHelpers.push({ documentType: '', file: '' }); e.preventDefault(); }}
          >
            Add one more document
          </a>
        </>
      )} />

      <div className="form-actions-edit py-4 row">
        <div className="col-6 col-sm-4 col-lg-3">
          <button
            className="btn btn form-btn-fill form-button"
            type="button"
            disabled={!linkName}
            onClick={onSubmit}
          >
            Save
          </button>
        </div>
        <div className="col-6 col-sm-4 col-lg-3">
          <button
            className="btn form-btn-outline"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </BootstrapForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  linkName: PropTypes.string,
  business: PropTypes.bool,
};

Form.defaultProps = {
  linkName: '',
  business: false,
};

export default Form;
