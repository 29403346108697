import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';

const QuestionnaireTab = () => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Questionnaire</h2>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name="questionnaire.source_of_assets_and_income"
        type="text"
        label="Source of assets and income"
        placeholder="Source of assets and income"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name="questionnaire.anticipated_monthly_cash_volume"
        type="text"
        label="Anticipated monthly cash volume"
        placeholder="Anticipated monthly cash volume"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name="questionnaire.anticipated_monthly_transactions_incoming"
        type="text"
        label="Anticipated monthly transactions incoming"
        placeholder="Anticipated monthly transactions incoming"
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name="questionnaire.anticipated_monthly_transactions_outgoing"
        type="text"
        label="Anticipated monthly transactions outgoing"
        placeholder="Anticipated monthly transactions outgoing"
        required
      />
    </Form.Row>
  </>
);

export default QuestionnaireTab;
