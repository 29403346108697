import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';
import TextField from 'components/helpers/TextField';
import Scrollspy from 'utils/react-scrollspy';

import StepsForm from './steps-form';
import { stepsFor, componentsFor } from './tabs';
import api from 'api';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const FORM_PREFIX = 'relatedContact';

const STEPS = {
  natural_person: ['personal', 'address', 'verification'],
  company: ['companyInfo', 'address', 'companyVerification']
};

const ContactForm = ({ contact, onSubmit, onCancel, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting } = formikBag;
  const steps = useMemo(() => stepsFor(STEPS[contact.contact_type], `${FORM_PREFIX}.`), [contact]);
  const components = useMemo(() => componentsFor(STEPS[contact.contact_type], `${FORM_PREFIX}.`), [contact]);
  const [tabsList, setVisited] = useState(contact.id || contact.changed ?
    steps.map(i => i.target) : steps.slice(0, 1).map(i => i.target));
  const isSubmitVisible = useMemo(() => tabsList.length === steps.map(i => i.target).length, [tabsList, steps]);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const formHeader = useMemo(() => {
    const action = contact.id ? 'Edit' : 'Add';
    const title = contact.contact_type === 'company' ?
      'Beneficial entity' : 'Person';
    return `${action} ${title}`;
  }, [contact.id, contact.contact_type]);

  const setVisitedTab = e => {
    const visited = e.target.dataset.visited;
    tabsList.push(visited);
    setVisited([...tabsList]);
  };

  const isVisited = (tab) => {
    return tabsList.some(item => item === tab);
  };

  const resetErrors = () => GlobalStore.update(s => {
    s.apiErrors = Object.entries(s.apiErrors).reduce((acc, [key, value]) => {
      if (!key.match(`${FORM_PREFIX}.`)) acc[key] = value;
      return acc;
    }, {});
  });

  const handleCancel = () => {
    if (dirty && !isSubmitting) {
      if (window.confirm("You are going to leave this page. The changes you've made won't be saved.")) {
        resetErrors();
        onCancel();
      }
    } else {
      resetErrors();
      onCancel();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.validateContact(serialize(values)).
      then(({ data: { attributes } }) => {
        const { uuid } = contact;
        onSubmit({ uuid, ...attributes});
      }).
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    if (data.errors) {
      const tabs = steps.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => {
        s.apiErrors = {
          ...s.apiErrors,
          ...Object.entries(data.errors).reduce((acc, [key, value]) => {
            acc[`${FORM_PREFIX}.${key}`] = value;
            return acc;
          }, {})
        };
      });

      if (tabs.length && !Object.keys(data.errors).includes('label')) {
        window.location.href = `#${tabs[0]}`;
      } else { document.querySelector('.modal-body').scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      document.querySelector('.modal-body').scrollTo(0, 0);
    }
  };

  const serialize = () => {
    return {
      contact: {
        ...values[FORM_PREFIX],
        status: 'pending',
      },
    };
  };

  return (
    <div className="steps_form">
      {isLoading && (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">{formHeader}</h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Scrollspy
            id="related_contact_steps_form"
            items={steps.map(i => i.target)}
            className={classnames(
              "steps_form__tabs-nav sticky-top pt-3",
              { 'steps_form__tabs-nav--is-invalid': status === 'inValid' }
            )}
            componentTag={Nav}
            offset={-10}
            currentClassName="active"
            rootEl=".modal-body"
          >
            {steps.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={classnames(
                  'steps_form__tabs-link',
                  {
                    'visited': isVisited(item.target),
                    'steps_form__tabs-link--is-invalid': invalidTabs.includes(item.target)
                  }
                )}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Scrollspy>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              contact.id || contact.changed ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <Row>
              <TextField
                formGroupProps={{ as: Col, md: 6 }}
                name={`${FORM_PREFIX}.label`}
                type="text"
                label="Title"
                placeholder="Title"
                required
              />
            </Row>
            <StepsForm
              components={components}
              onChangeTab={setVisitedTab}
              visitedTabs={tabsList}
              onCancel={handleCancel}
              localValues={values[FORM_PREFIX]}
              fieldPrefix={`${FORM_PREFIX}.`}
            />
          </div>
          {isSubmitVisible && (
            contact.id || contact.changed ? (
              <div className="form-actions-edit py-4 row">
                <div className="col-6 col-sm-4 col-lg-3">
                  <button
                    className="btn form-btn-fill form-button"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                <div className="col-6 col-sm-4 col-lg-3">
                  <button
                    className="btn form-btn-outline"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className="form-actions mt-4">
                <div className="d-sm-inline-block mb-3 mr-3">
                  <button
                    className="btn form-btn-fill form-button"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
                <div className="d-sm-inline-block mb-3">
                  <button
                    className="btn form-btn-outline"
                    type="button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
ContactForm.defaultProps = {
};

export default withFormik({
  mapPropsToValues: ({ contact }) => {
    return {
      [FORM_PREFIX]: Object.entries(contact).reduce((acc, [key, value]) => {
        if (value instanceof Object && value instanceof Array === false) {
          acc[key] = {};
          Object.entries(value).forEach(([k, v]) => acc[key][k] = v === null ? '' : v);
          return acc;
        }
        acc[key] = value === null ? '' : value;
        return acc;
      }, {}),
    };
  },
})(ContactForm);
