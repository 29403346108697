import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DepositForm from '../dwolla-v2/deposit-form/index';
import classnames from 'classnames';
import WithdrawForm from '../dwolla-v2/withdraw-form';
import BankAccountLink from '../dwolla-v2/bank-account-link';
import { GeneralTab, TransfersTab, DocumentsTab, BankAccountTab } from './tabs';


const Account = ({ account: { id, attributes, links } }) => {
  const {
    xid,
    full_name,
    status,
    status_label,
    balance,
    masked_iban,
    pending_funds,
    debited_funds,
    type_label,
    bank_account,
    owner_contact: { beneficial_owners }
  } = attributes;
  const { link_bank_account, deposit, withdraw, money_transfer, edit_bank_account, funds_transfers, documents } = links;

  const beneficials = useMemo(() =>
    beneficial_owners?.filter(beneficial => beneficial.links.documents), [beneficial_owners]
  );

  return (
    <div className="sub-accounts-details">
      <div className="sub-accounts-list__item-header pb-1">
        <div className="sub-accounts-list__item-type">{type_label}</div>
        <div
          className={classnames(
            'sub-accounts-list__item-status ml-2',
            `sub-accounts-list__item-status--${status}`
          )}>
          {status_label}
        </div>
      </div>

      <Row className="justify-content-between align-items-center pb-2">
        <Col xs={6} md={6}>
          <h1 className="sub-account-details__heading">{full_name}</h1>
        </Col>

        {money_transfer ? (
          <Col xs="auto" className="d-md-none">
						<a
              className="btn form-btn-outline sub-accounts-list__item-edit sub-accounts-list__item-btn"
              href={money_transfer}
            >
              Transfer
            </a>
          </Col>
        ) : null}

        <Col xs={12} md={6} className="text-md-right text-left">
          {masked_iban ? (
            <div className="sub-account-details__heading">{masked_iban}</div>
          ) : null}
        </Col>
      </Row>

      <hr className="sub-accounts-list__line" />

      <Row className="justify-content-between align-items-center pb-2">
        <Col>
          <div className="sub-account-details__heading tex-left text-light">
            {status === "approved" ? balance : `--`}
          </div>
        </Col>

        <Col className="d-flex justify-content-end">
          {withdraw ? (
            <div className="d-none d-md-block text-right">
              <WithdrawForm sub_account_id={id} />
            </div>
          ) : null}

          <div className="text-right ml-2">
            {masked_iban ? (
              <DepositForm sub_account_id={id} disabled={!deposit} buttonClass="sub-accounts-list__item-btn" />
            ) : (
              <BankAccountLink link={link_bank_account} />
            )}
          </div>
        </Col>
      </Row>

      <Row className="justify-content-between align-items-center">
        <Col xs={6}>
          <div className="d-flex align-items-center justify-content-start sub-accounts-details__processed-money">
            <div className="info-column mr-4">
              <div>Pending</div>
              <div className="text-light">
                {status === "approved" ? `+${pending_funds}` : `--`}
              </div>
            </div>

            <div className="info-column">
              <div>Debited</div>
              <div className="text-light">
                {status === "approved" ? `-${debited_funds}` : `--`}
              </div>
            </div>
          </div>
        </Col>

        <Col className="d-flex justify-content-end">
          {withdraw ? (
            <div className="d-md-none text-right">
              <WithdrawForm sub_account_id={id} />
            </div>
          ) : null}

          {money_transfer ? (
            <div className="d-none d-md-block text-right">
              <a
                className="btn form-btn-outline sub-accounts-list__item-edit sub-accounts-list__item-btn"
                href={money_transfer}>
                Transfer
              </a>
            </div>
          ) : null}
        </Col>
      </Row>

      <Tabs className="sub-account-details__tabs" defaultActiveKey="details" variant="pills" mountOnEnter>
        <Tab tabClassName="sub-account-details__tabs-link" eventKey="details" title="Details">
          <div className="sub-account-details__tabs-content">
            <GeneralTab attributes={attributes} accountId={id} />
          </div>
        </Tab>
        {bank_account ? (
          <Tab tabClassName="sub-account-details__tabs-link" eventKey="bankAccount" title="Bank accounts">
            <div className="sub-account-details__tabs-content">
              <BankAccountTab bank_account={bank_account} edit_bank_account={edit_bank_account} />
            </div>
          </Tab>
        ) : null}
        {funds_transfers ? (
          <Tab tabClassName="sub-account-details__tabs-link" eventKey="transfers" title="Transfers">
            <div className="sub-account-details__tabs-content">
              <TransfersTab id={id} full_name={full_name} links={links} />
            </div>
          </Tab>
        ) : null}
        {documents || beneficials?.length ? (
          <Tab tabClassName="sub-account-details__tabs-link" eventKey="documents" title="Documents">
            <div className="sub-account-details__tabs-content">
              <DocumentsTab xid={xid} name={full_name} link={documents} beneficials={beneficials} />
            </div>
          </Tab>
        ) : null}
      </Tabs>
    </div>
  );
};

Account.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      status_label: PropTypes.string.isRequired,
      balance: PropTypes.string.isRequired,
      masked_iban: PropTypes.string,
      pending_funds: PropTypes.string.isRequired,
      debited_funds: PropTypes.string.isRequired,
      type_label: PropTypes.string.isRequired,
      bank_account: PropTypes.object,
      xid: PropTypes.string.isRequired,
      owner_contact: PropTypes.shape({
        beneficial_owners: PropTypes.array
      }).isRequired
    }).isRequired,
    links: PropTypes.shape({
      details: PropTypes.string,
      edit: PropTypes.string,
      link_bank_account: PropTypes.string,
      deposit: PropTypes.string,
      withdraw: PropTypes.string,
      money_transfer: PropTypes.string,
      edit_bank_account: PropTypes.string,
      funds_transfers: PropTypes.string,
      documents: PropTypes.string,
    }).isRequired,
  }),
};

export default Account;
