import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'globals/constants.js.erb';

export const Contacts = ({ owner_contact }) => {
  const { street, email, city, region, postal_code, date_of_birth } = owner_contact;

  return (
    <div className="sub-account-details__base-info">
      <h2 className="sub-account-details__base-info__title">Contacts</h2>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Email</div>
        <div className="sub-account-details__base-info__field-value">{email}</div>
      </div>
      {date_of_birth ? (
        <div className="sub-account-details__base-info__field">
          <div className="sub-account-details__base-info__field-name">Date of birth</div>
          <div className="sub-account-details__base-info__field-value">{format(Date.parse(date_of_birth), DATE_FORMAT)}</div>
        </div>
      ) : null}
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">Address</div>
        <div className="sub-account-details__base-info__field-value">{street}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">City</div>
        <div className="sub-account-details__base-info__field-value">{city}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">State</div>
        <div className="sub-account-details__base-info__field-value">{region}</div>
      </div>
      <div className="sub-account-details__base-info__field">
        <div className="sub-account-details__base-info__field-name">ZIP</div>
        <div className="sub-account-details__base-info__field-value">{postal_code}</div>
      </div>
    </div>
  );
};

Contacts.propTypes = {
  accountId: PropTypes.string.isRequired,
  owner_contact: PropTypes.shape({
    email: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postal_code: PropTypes.string,
    street: PropTypes.string,
    date_of_birth: PropTypes.string,
  }).isRequired,
};
