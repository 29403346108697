import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';
import axios from 'axios';
import api from 'api';
import { withFormik } from 'formik';

import Spinner from 'components/helpers/Spinner';
import Form from './form';

import GlobalStore from 'stores/global-store';

const AVAILABLE_DWOLLA_LINK_NAMES = [
  'verify-with-document', 'verify-business-with-document', 'verify-controller-and-business-with-document'
];

const Modal = ({ resource_link, show, onCancel, onSuccess, ...formikBag }) => {
  const [isLoading, setLoading] = useState(false);
  const [resource, setResource] = useState({ });
  const [linkName, setLinkName] = useState(null);
  const [token, setToken] = useState();
  const { values, resetForm, setStatus } = formikBag;

  useEffect(() => {
    api.createDwollaAccessToken().then((res) => {
      setToken(res.data.access_token);
    });
  }, []);

  const dwollaClient = useMemo(() => axios.create({
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/vnd.dwolla.v1.hal+json',
      'Content-Type': 'application/json'
    }
  }), [token]);
  const fetchResource = () => {
    setLoading(true);

    dwollaClient.get(resource_link).
      then(({ data }) => {
        setResource(data);
        const link = AVAILABLE_DWOLLA_LINK_NAMES.reduce((acc, name) => {
          // eslint-disable-next-line
          acc = Object.keys(data._links).includes(name) ? name : acc
          return acc;
        }, '');
        setLinkName(link);
      }).
      finally(() => setLoading(false));
  };

  const handleSubmit = () => {
    setLoading(true);
    GlobalStore.update(s => { s.apiErrors = {}; });
    Promise.allSettled(values.documents.map(document => {
      const formData = new FormData;
      Object.entries(document).forEach(([key, value]) => {
        formData.append(key, value);
      });
      return dwollaClient.post(resource._links[linkName].href, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .catch(({ response }) => response);
    })).then((values) => {
      const errors = {};
      values.forEach(({ value: response }, index) => {
        // eslint-disable-next-line
        if(response.status > 201) {
          setStatus('inValid');
          const apiErrors = response.data._embedded?.errors || [];
          if(apiErrors.length) {
            apiErrors.forEach(apiError => errors[`documents.${index}.${apiError.path.replace('/', '')}`] = [apiError.message]);
          } else {
            errors[`documents.${index}.basic`] = response.data.message;
          }
        }
      });
      if(Object.entries(errors).length) {
        GlobalStore.update(s => { s.apiErrors = errors; });
      } else {
        handleCancel();
      }
      onSuccess();
      setLoading(false);
    });
  };

  const handleCancel = () => {
    GlobalStore.update(s => { s.apiErrors = {}; });
    resetForm();
    onCancel();
  };

  return (
    <>
      <Spinner show={isLoading} />
      <BootstrapModal
        backdrop="static"
        keyboard={true}
        size="lg"
        scrollable
        onHide={handleCancel}
        show={show}
        onShow={fetchResource}
      >
        {isLoading ? null : (
          <>
            <BootstrapModal.Header>
              <BootstrapModal.Title>
                {resource?.firstName} {resource?.lastName}. Verification documents
                <div className="text-muted">
                  You need to upload additional pieces of information in order to verify the account
                </div>
              </BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className="pb-0">
              <Form business={resource?.type === 'business'} linkName={linkName} onSubmit={handleSubmit} onCancel={handleCancel} />
            </BootstrapModal.Body>
          </>
        )}
      </BootstrapModal>
    </>
  );
};


Modal.propTypes = {
  resource_link: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    documents: [{
      documentType: '', file: ''
    }]
  })
})(Modal);
