import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const AgreementPreviewModal = ({ onDismiss, onAccept, content, ...rest }) => {
  const handleDismiss = () => {
    if (onDismiss) onDismiss();
  };
  const handleAccept = () => {
    if (onAccept) onAccept();
  };

  return (
    <Modal
      onHide={handleDismiss}
      backdrop="static"
      keyboard={false}
      size="xl"
      scrollable
      {...rest}
    >
      <Modal.Body dangerouslySetInnerHTML={{__html: content}} />
      <Modal.Footer>
        <div className="w-100 py-4 row justify-content-end">
          <div className="col-6 col-sm-6 col-lg-3">
            <button className="btn btn form-btn-fill form-button" onClick={handleAccept}>Accept</button>
          </div>
          <div className="col-6 col-sm-6 col-lg-3">
            <button className="btn form-btn-outline form-button" onClick={handleDismiss}>Dismiss</button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};


AgreementPreviewModal.propTypes = {
  onDismiss: PropTypes.func,
  onAccept: PropTypes.func,
  content: PropTypes.string
};

AgreementPreviewModal.defaultProps = {
  onDismiss: null,
  onAccept: null,
  content: ''
};

export default AgreementPreviewModal;
