import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import Form from '../contacts/form';

const ContactForm = ({ contact, onDismiss, onAccept, formPrefix, ...rest }) => {
  if (contact.identity_confirmed) return null;

  return (
    <Modal
      onHide={onDismiss}
      backdrop="static"
      keyboard={false}
      size="xl"
      scrollable
      {...rest}
    >
      <Modal.Body className="pb-0">
        <Form
          contact={contact}
          onSubmit={onAccept}
          onCancel={onDismiss}
          formPrefix={formPrefix}
        />
      </Modal.Body>
    </Modal>
  );
};


ContactForm.propTypes = {
  onDismiss: PropTypes.func,
  onAccept: PropTypes.func,
  contact: PropTypes.object,
  formPrefix: PropTypes.string
};

ContactForm.defaultProps = {
  onDismiss: null,
  onAccept: null,
  contact: {},
  formPrefix: 'beneficialOwners',
};

export default ContactForm;
