import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DocumentList = ({ name, documents }) => (
  <>
    {documents?.length ?
      documents.map((document) => {
        const comment = document.allFailureReasons?.map(failure => failure.description)?.join('. ');

        return (
          <div className="document-list__row" key={document.id}>
            <div className="document-list__cell document-list__cell--column-1 pl-0">
              {name}
            </div>
            <div className="document-list__cell document-list__cell--column-2 pl-0 d-none d-sm-block">
              {document.type}
            </div>
            <div className="document-list__cell document-list__cell--column-3 pl-0 flex-grow">
              {comment}
            </div>
            <div className="document-list__cell document-list__cell--column-4 pl-0">
              <span
                className={classnames(
                  'sub-accounts-list__item-status',
                  `sub-accounts-list__item-status--${document.documentVerificationStatus}`
                )}
              >
                {document.documentVerificationStatus}
              </span>
            </div>
          </div>
        );
      }) : null}
  </>
);

DocumentList.propTypes = {
  name: PropTypes.string.isRequired,
  documents: PropTypes.array,
};

DocumentList.defaultProps = {
  documents: []
};

export default DocumentList;
