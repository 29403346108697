import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import FileField from 'components/helpers/FileField';

const VisualsTab = () => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Visuals</h2>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="images"
        label="Images"
        accept={['image/gif', 'image/jpeg', 'image/pjpeg', 'image/png']}
        type="Attachments::Image"
        multiple
        withGallery
        required
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="rendering_file"
        label="Rendering"
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="floorplan_file"
        label="Floorplan"
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="drawing_file"
        label="Drawing"
      />
    </Form.Row>

    <Form.Row>
      <FileField
        formGroupProps={{ as: Col, xs:12 }}
        name="elevations_file"
        label="Elevations"
      />
    </Form.Row>
  </>
);

export default VisualsTab;
