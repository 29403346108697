import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './modal';

const WithdrawForm = ({ disabled, ...rest }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => setOpenModal(true);
  const handleCancel = () => setOpenModal(false);

  return (
    <>
      <button
        className="btn form-btn-outline sub-accounts-list__item-btn px-auto"
        onClick={handleClick}
        disabled={disabled}>
        Withdraw
      </button>
      {disabled ? null : (
        <Modal show={openModal} onCancel={handleCancel} {...rest} />
      )}
    </>
  );
};

WithdrawForm.propTypes = {
  disabled: PropTypes.bool,
};

WithdrawForm.defaultProps = {
  disabled: false
};

export default WithdrawForm;
