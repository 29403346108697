import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['feedback', 'field', 'acceptance', 'submitButton', 'spinner'];
  errors = [];

  handleSuccess({ detail: [data] }) {
    if (data.redirect_to) {
      window.location.href = data.redirect_to;
    } else {
      window.location.reload();
    }
  }

  handleError({ detail: [data, statusMessage] }) {
    this.resetErrors();
    if (data.errors) {
      Object.entries(data.errors).forEach(this.applyError);
    } else {
      this.feedbackTarget.innerHTML = statusMessage;
    }
  }

  validateAcceptance() {
    if (!this.hasAcceptanceTarget || !this.hasSubmitButtonTarget) return;
    const fieldsFilled = this.hasFieldTarget ? this.fieldTargets.every(e => e.value !== '') : true;
    if (this.acceptanceTargets.every(e => e.checked) && fieldsFilled) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  resetErrors() {
    this.feedbackTarget.innerHTML = '';
    this.errors.forEach(node => node.remove());
    this.errors = [];
  }

  applyError = ([key, errors]) => {
    if (key === 'base') {
      const message = errors instanceof Object ? errors.join('. ') : errors;
      this.feedbackTarget.innerHTML = message;
    }

    const inputGroup = this.element.querySelector(`.${this.element.name}_${key}`);
    if (inputGroup && errors) {
      const errorTarget = document.createElement("div");
      errorTarget.classList.add('invalid-feedback');
      errorTarget.innerHTML = errors.join('. ');
      inputGroup.appendChild(errorTarget);
      this.errors.push(errorTarget);
    }
  }

  submit() {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
      this.submitButtonTarget.textContent = this.submitButtonTarget.dataset.disableWith;
      const span = document.createElement('span');
      span.classList.add('ml-2', 'spinner-border', 'spinner-border-sm');
      span.role = 'status';
      span.ariaHidden = true;
      this.submitButtonTarget.appendChild(span);
    }
    this.element.submit();
  }
}
