import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';

import api from 'api';
import useBeforeUnload from 'components/helpers/useBeforeUnload';
import TextField from 'components/helpers/TextField';
import StepsForm from 'components/helpers/StepsForm';
import { stepsFor, componentsFor } from './tabs';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const STEPS = ['personal', 'address', 'verification'];

const ContactForm = ({ contact, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting, setFieldValue } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);
  const { steps, components } = useMemo(() => ({ steps: stepsFor(STEPS), components: componentsFor(STEPS) }), [contact]);

  const [tabsList, setVisited] = useState(contact.id ?
    steps.map(i => i.target) : steps.slice(0, 1).map(i => i.target));
  const isSubmitVisible = useMemo(() => tabsList.length === steps.map(i => i.target).length, [tabsList, steps]);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const formHeader = useMemo(() => (contact.id ?
    `Edit ${contact.attributes.related_to} Beneficial Owner` : `Add ${contact.attributes.related_to} Beneficial Owner`
  ), [contact.id, contact.attributes.related_to]);

  const setVisitedTab = e => {
    const visited = e.target.dataset.visited;
    tabsList.push(visited);
    setVisited([...tabsList]);
  };

  const isVisited = (tab) => {
    if (contact.id) { return true; }

    return tabsList.some(item => item === tab);
  };

  const submitForm = () => {
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postSubAccountsBeneficialOwners(contact.attributes.sub_account_link, contact.id, serialize(values)).
      then(() => window.location.href = '/profile/sub_accounts').
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm();
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    setFieldValue('certified', false);
    if (data.errors) {
      const tabs = steps.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const handleCancel = () => window.location.href = '/profile/sub_accounts';

  const serialize = () => {
    return {
      contact: {
        ...values,
        status: 'pending'
      },
    };
  };

  return (
    <div className="steps_form">
      {isLoading && (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">{formHeader}</h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {steps.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link',
                  isVisited(item.target) ? 'visited' : '',
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              contact.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <Row>
              <TextField
                formGroupProps={{ as: Col, md: 6 }}
                name="label"
                type="text"
                label="Title"
                placeholder="Title"
                required
              />
            </Row>
            <StepsForm
              components={components}
              onChangeTab={setVisitedTab}
              visitedTabs={tabsList}
              onCancel={handleCancel}
              localValues={values}
            />
          </div>
          {isSubmitVisible && (
            <>
              {contact.id ? (
                <div className="form-actions-edit py-4 row">
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading || !values.certified}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-actions mt-4">
                  <div className="d-sm-inline-block mb-3 mr-3">
                    <button
                      className="btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="d-sm-inline-block mb-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

ContactForm.propTypes = {
  contact: PropTypes.object,
};
ContactForm.defaultProps = {
  contact: {}
};

export default withFormik({
  mapPropsToValues: ({ contact }) => ({
    label: contact?.attributes?.label || '',
    first_name: contact?.attributes?.first_name || '',
    last_name: contact?.attributes?.last_name || '',
    date_of_birth: contact?.attributes?.date_of_birth || '',
    street: contact?.attributes?.street || '',
    city: contact?.attributes?.city || '',
    country: contact?.attributes?.country || '',
    region: contact?.attributes?.region || '',
    postal_code: contact?.attributes?.postal_code || '',
    ssn: contact?.attributes?.ssn || '',
    passport_number: contact?.attributes?.passport_number || '',
    passport_country: contact?.attributes?.passport_country || '',
    certified: false,
  }),
})(ContactForm);
