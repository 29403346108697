import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import classnames from 'classnames';
import { withFormik } from 'formik';

import StepsForm from 'components/helpers/StepsForm';
import CheckBox from 'components/helpers/CheckBox';
import CustomSelect from 'components/helpers/CustomSelect';
import TermsAndConditionsLabel from 'components/helpers/TermsAndConditionsLabel';
import useBeforeUnload from 'components/helpers/useBeforeUnload';
import Spinner from 'components/helpers/Spinner';
import { stepsFor, componentsFor } from './tabs';
import api from 'api';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const STEPS = {
  natural_person: ['personal', 'address'],
  company: ['personal', 'address', 'companyInfo', 'controller', 'beneficialOwners']
};

const SubAccountForm = ({ ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting, setFieldValue } = formikBag;
  useBeforeUnload(false && dirty && !isSubmitting);

  const { steps, components } = useMemo(() => {
    return {
      steps: stepsFor(STEPS[values.type]),
      components: componentsFor(STEPS[values.type])
    };
  }, [values.type]);
  const [visited, setVisited] = useState((values.id ?
    steps.map(i => i.target) : steps.slice(0, 1).map(i => i.target)));
  const isSubmitVisible = useMemo(() => visited.length === steps.map(i => i.target).length, [visited, steps]);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const setVisitedTab = e => {
    const tab = e.target.dataset.visited;
    visited.push(tab);
    setVisited([...visited]);
  };

  const isVisited = (tab) => {
    if (values.id) { return true; }

    return visited.some(item => item === tab);
  };

  const submitForm = () => {
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postSubAccount(values.id, serialize(values)).
      then(() => window.location.href = '/profile/sub_accounts').
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitForm();
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    setFieldValue('terms', false);
    if (data.errors) {
      const tabs = steps.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else if (Object.keys(data.errors).includes('terms')) {
        window.scrollTo(0, document.body.scrollHeight);
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const handleCancel = () => window.location.href = '/profile/sub_accounts';
  const handleChangeType = (event) => {
    const type = event.target.value;
    setFieldValue('type', type);
    const newSteps = stepsFor(STEPS[type]);
    setVisited((values.id ?
      newSteps.map(i => i.target) : newSteps.slice(0, 1).map(i => i.target)));
  };

  const serialize = () => {
    const owner_contact_attributes = {
      ...values.owner_contact,
      type: values.type === 'company' ? 'Contacts::Company' : 'Contacts::NaturalPerson',
      controller_attributes: values.owner_contact.controller,
      controller: null,
      beneficial_owners_attributes: values.owner_contact.beneficial_owners,
      beneficial_owners: null,
    };
    if (values.type !== 'company') {
      delete owner_contact_attributes.controller_attributes;
      delete owner_contact_attributes.controller;
      delete owner_contact_attributes.beneficial_owners_attributes;
      delete owner_contact_attributes.beneficial_owners;
      delete owner_contact_attributes.business_name;
      delete owner_contact_attributes.ein;
    }
    return {
      sub_account: {
        ...values,
        status: 'pending',
        owner_contact: null,
        owner_contact_attributes
      },
    };
  };

  return (
    <div className="steps_form">
      <Spinner show={isLoading} />
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">
            {values.id ? `Edit an account` : `Add new account`}
          </h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {steps.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link',
                  isVisited(item.target) ? 'visited' : '',
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          {values.id ? null : (
            <Row>
              <CustomSelect
                formGroupProps={{ as: Col, md: 6 }}
                label="Account type"
                name='type'
                options={[
                  { value: 'natural_person', title: 'Personal' },
                  { value: 'company', title: 'Business' },
                ]}
                onChange={handleChangeType}
                disabled={values.id}
                required
              />
            </Row>
          )}
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              values.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <StepsForm
              components={components}
              onChangeTab={setVisitedTab}
              visitedTabs={visited}
              onCancel={handleCancel}
              localValues={values.owner_contact}
              fieldPrefix="owner_contact."
            />
          </div>
          {isSubmitVisible && (
            <>
              <CheckBox
                name="terms"
                label={
                  <>
                    <TermsAndConditionsLabel />
                    <span>&nbsp;as well as the&nbsp;</span>
                    <a
                      href="https://www.dwolla.com/legal/dwolla-account-terms-of-service/#legal-content"
                      target="_blank"
                      rel="noreferrer"
                    >Terms of Service</a>
                    <span>&nbsp;and&nbsp;</span>
                    <a
                      href="https://www.dwolla.com/legal/privacy/#legal-content"
                      target="_blank"
                      rel="noreferrer"
                    >Privacy Policy</a>
                    <span>&nbsp;of Dwolla, which is a partner of SimKey</span>
                  </>}
              />
              {values.id ? (
                <div className="form-actions-edit py-4 row">
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={values.terms !== true || isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-actions mt-4">
                  <div className="d-sm-inline-block mb-3 mr-3">
                    <button
                      className="btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={values.terms !== true || isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="d-sm-inline-block mb-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

SubAccountForm.propTypes = {
  sub_account: PropTypes.object,
};
SubAccountForm.defaultProps = {
  sub_account: {}
};

export default withFormik({
  mapPropsToValues: ({ sub_account }) => {
    const attributes = sub_account?.attributes;
    return (
      {
        id: attributes?.id,
        type: attributes?.type ?? 'natural_person',
        terms: false,
        owner_contact: attributes?.owner_contact ?? {
          first_name: '',
          last_name: '',
          email: '',
          date_of_birth: '',
          ssn: '',
          street: '',
          city: '',
          country: 'US',
          region: '',
          postal_code: '',
          business_name: '',
          ein: '',
        }
      }
    );
  },
})(SubAccountForm);
