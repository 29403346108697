import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import { v4 as uuid } from 'uuid';

import ContactFormModal from '../modals/contact-form';
import ContactCard from '../contacts/card';
import { useFormikContext } from 'formik';
import api from 'api';
import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const BeneficialOwnersTab = ({ localValues, fieldPrefix }) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeContact, setActiveContact] = useState({});
  const { setFieldValue } = useFormikContext();

  const addContact = (contact) => {
    const beneficialOwners = localValues.beneficial_owners || [];
    contact.changed = true;
    const index = contact.id ?
      beneficialOwners.findIndex(c => c.id === contact.id) : beneficialOwners.findIndex(c => c.uuid === contact.uuid);
    if (index >= 0) {
      beneficialOwners[index] = contact;
    } else {
      beneficialOwners.push(contact);
    }
    setFieldValue(`${fieldPrefix}beneficial_owners`, beneficialOwners);
    setOpenModal(false);
    resetApiError(`${fieldPrefix}beneficial_owners`);
  };

  const handleAddContactClick = () => openNewForm({ type: 'Contacts::NaturalPerson' });

  const openEditForm = (contact) => () => {
    setActiveContact(contact);
    setOpenModal(true);
  };

  const openNewForm = ({ type }) => {
    resetApiError(`${fieldPrefix}beneficial_owners`);
    GlobalStore.update(s => { s.isLoading = true; });
    api.buildContact({ type }).
      then(({ data: { attributes } }) => {
        setActiveContact({ uuid: uuid(), ...attributes });
        setOpenModal(true);
      }).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
      });
  };

  const resetBaseApiError = () => resetApiError('base');

  const addPersonButton = () => {
    return (
      <button
        className="btn mr-2 btn-outline-secondary"
        type="button"
        data-contact-type="Contacts::NaturalPerson"
        onClick={handleAddContactClick}
      >
        Add Beneficial Owner (Individual)
      </button>
    );
  };

  const renderTabError = () => {
    const errorMessage = readApiError(`${fieldPrefix}beneficial_owners`);
    return errorMessage ? (
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    ) : null;
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Beneficial Owners</h2>

      <Form.Row className="mb-3">
        <Col md={12}>
          <Accordion>
            {localValues.beneficial_owners?.length ? (
              localValues.beneficial_owners.map(
                (contact) =>
                  <ContactCard
                    key={contact.id || contact.uuid}
                    contact={contact}
                    onEditClick={openEditForm(contact)}
                  />
              )
            ) : null}
          </Accordion>
        </Col>
      </Form.Row>
      {renderTabError()}

      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Text className="text-muted">
            FinCen defines a Beneficial Owner as:
            <br />
            1. Each individualism if any, who, directly or indirectly, owns 25% or more of the equity interests
            of a legal entity customer (i.e., the ownership prong);
          </Form.Text>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md={12}>
          {addPersonButton()}
        </Form.Group>
      </Form.Row>
      <ContactFormModal
        contact={activeContact}
        show={openModal}
        onAccept={addContact}
        onEnter={resetBaseApiError}
        onExit={resetBaseApiError}
        onDismiss={() => setOpenModal(false)}
      />
    </>
  );
};

BeneficialOwnersTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

BeneficialOwnersTab.defaultProps = {
  fieldPrefix: ''
};

export default BeneficialOwnersTab;
