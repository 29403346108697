import React from 'react';
import ReactDOM from 'react-dom';
import { Controller } from "@hotwired/stimulus";
import components from 'components/index';

export default class extends Controller {
 static targets = ['component']

 connect() {
   // this.componentTargets.forEach((node) => {
   //   if (node.getAttribute('mounted')) return;
   //   const { name, props } = node.dataset;
   //   const Component = components[name];
   //   ReactDOM.render(
   //     <Component {...JSON.parse(props)} />,
   //     node.appendChild(document.createElement('div'))
   //   );
   //   node.setAttribute('mounted', true);
   // });
 }

 componentTargetConnected(node) {
   if (node.getAttribute('mounted')) return;
   const { name, props } = node.dataset;
   const Component = components[name];
   ReactDOM.render(
     <Component {...JSON.parse(props)} />,
     node.appendChild(document.createElement('div'))
   );
   node.setAttribute('mounted', true);
 }
}
