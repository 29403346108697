import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { api as axios } from 'api/instances';

import { isLoading, setIsLoading } from 'stores/global-store';
import Spinner from 'components/helpers/Spinner';
import Account from './Account';

const SubAccountDetails = () => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios.get(window.location.pathname).then((res) => {
      setAccount(res.data);
    }).finally(() => setIsLoading(false));
  }, []);

  return (
    <Row className="page-wrapper">
			<div className="col-xl-2 col-lg-2 d-none d-lg-block"></div>
			<Col className="col-xl-8 col-lg-10 col-md-10 mx-0 mx-md-auto">
				<Spinner show={isLoading()} />
        {account ? <Account account={account} /> : null}
      </Col>
    </Row>
  );
};

export default SubAccountDetails;
