import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { useFormikContext } from 'formik';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';

import { resetApiError } from 'stores/global-store';

import {
  USA_STATES,
  COUNTRIES,
} from 'globals/constants.js.erb';

const AddressTab = ({ fieldPrefix, localValues }) => {
  const { setFieldValue } = useFormikContext();

  const onCountryChange = (event) => {
    if (event.target.value === localValues.country) return;

    setFieldValue(`${fieldPrefix}country`, event.target.value);
    resetApiError(`${fieldPrefix}country`);

    if (localValues.country === 'US' || event.target.value === 'US') {
      setFieldValue(`${fieldPrefix}region`, '');
      resetApiError(`${fieldPrefix}region`);
    }

  };

  const renderStateOrRegion = () => {
    if (localValues.country === 'US') {
      return (
        <CustomSelect
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          label="State"
          name={`${fieldPrefix}region`}
          placeholder="State"
          options={USA_STATES}
          required
        />
      );
    }
    return (
      <TextField
        formGroupProps={{ as: Col, sm: 6, xl: 3 }}
        name={`${fieldPrefix}region`}
        type="text"
        label="Two-letter state, province, or region ISO 3166-1 abbreviation code"
        placeholder="Region"
        required
      />
    );
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Address</h2>

      <Form.Row style={{marginTop: '-0.5rem'}}>
        <Col md={6} className="mb-3">
          <small className="text-muted">Permanent residence</small>
        </Col>
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}street`}
          type="text"
          label="Street address(physical address only)"
          placeholder="Street"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}city`}
          type="text"
          label="City"
          placeholder="City"
          required
        />
      </Form.Row>

      <Form.Row>
        <CustomSelect
          formGroupProps={{ as: Col, md: 6 }}
          label="Country"
          name={`${fieldPrefix}country`}
          placeholder="Country"
          options={COUNTRIES}
          onChange={onCountryChange}
          required
        />
        {renderStateOrRegion()}
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 3 }}
          name={`${fieldPrefix}postal_code`}
          type="text"
          label="Zip / Postal code"
          placeholder="ZIP"
          format={RegExp(/[\d,-]/g)}
          required={localValues.country === 'US'}
        />
      </Form.Row>
    </>
  );
};

AddressTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

AddressTab.defaultProps = {
  fieldPrefix: ''
};

export default AddressTab;
