import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useFormikContext, FieldArray } from 'formik';

import {
  USA_STATES,
  ASSET_TYPES,
  OCCUPANCIES,
  RESIDENTIAL_TYPES,
  FEATURES,
  BUILT_IN_MAX,
  BUILT_IN_MIN,
  TOTAL_UNITS_MAX
} from 'globals/constants.js.erb';
import CustomSelect from 'components/helpers/CustomSelect';
import TextField from 'components/helpers/TextField';
import api from 'api';
import GlobalStore, { resetApiError } from 'stores/global-store';

// Allowed year included in range BUILT_IN_MIN..BUILT_IN_MAX
/* eslint-disable-next-line no-magic-numbers */
const YEAR_OPTIONS = Array.from({ length: BUILT_IN_MAX - BUILT_IN_MIN + 1 }, (_, i) => i + BUILT_IN_MIN);

const GeneralTab = ({ fetchSubAccounts }) => {
  const [subAccountsOptions, setSubAccounts] = useState([]);
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.asset_type === 'vacant_land') handleChangeUnits();

    if (!subAccountsOptions.length) {
      GlobalStore.update(s => { s.isLoading = true; });
      fetchSubAccounts(values).then(({ data }) => {
        const options = data.map(item => ({ value: item.attributes.id, title: item.attributes.full_name }));
        setSubAccounts(options);
      }).catch(({ response }) => {
        GlobalStore.update(s => { s.apiErrors = { ...s.apiErrors, sub_account: response.data?.errors }; });
      }).finally(() => GlobalStore.update(s => { s.isLoading = false; }));
    }
  }, []);

  const handleChangeFeatures = ({ target: { id, checked, name } }) => {
    const features = values.features;
    features[id] = checked;
    setFieldValue(name, features);
  };

  const restoreOrCreateUnit = () => {
    const { units_attributes: removedUnits } = values;
    if (removedUnits.length) {
      const unit = removedUnits.pop();
      unit._destroy = false;
      setFieldValue('units_attributes', removedUnits, false);
      return unit;
    } else {
      return { id: '', beds: '', annual_revenue: '' };
    }
  };

  const handleChangeAssetType = () => {
    if (values.units.length < 1) {
      setFieldValue('units', [restoreOrCreateUnit()]);
    }
    if (values.asset_type === 'vacant_land') handleChangeUnits();
  };

  const handleChangeUnits = () => {
    const total = parseInt(values.total_units || 0, 10);
    if (total === values.units.length) { return; }
    let { units, units_attributes: removedUnits } = values;
    if (total < units.length) {
      removedUnits = units.splice(total).reverse().map(u => ({ ...u, _destroy: true }));
    } else {
      units = [
        ...units,
        ...Array.from({ length: total - units.length }).map(() => restoreOrCreateUnit())
      ];
    }
    setFieldValue('units', units);
    setFieldValue('units_attributes', removedUnits);
  };

  const renderUnits = (arrayHelpers) => {
    const addUnit = () => arrayHelpers.push(restoreOrCreateUnit());
    const removeUnit = index => {
      const units = values.units;
      arrayHelpers.remove(index);
      if (units[index].id) {
        units[index]._destroy = true;
        setFieldValue('units_attributes', [...values.units_attributes, units[index]]);
      }
      resetApiError(`units[${index}].beds`);
    };

    return (
      <Col md={6}>
        {values.units.map((unit, index) => {
          const label = `Unit ${index + 1} beds`;
          const beds = unit.beds === null ? '' : unit.beds;
          return (
            <TextField
              key={`units.${index}`}
              name={`units[${index}].beds`}
              label={
                <Form.Label className="flex-label" title={label} required>
                  <span className="form-label__text">{label}<abbr>&nbsp;*</abbr></span>
                  {values.units.length > 1 && index === values.units.length - 1 ? (
                    <a
                      href="#"
                      className="text-danger"
                      onClick={(e) => { removeUnit(index); e.preventDefault(); }}
                    >
                      Remove
                    </a>
                  ) : null}
                </Form.Label>
              }
              value={beds}
              placeholder="Beds"
              type="number"
              format={RegExp(/\d/g)}
              min={1}
              max={999}
              step={1}
              required
            />
          );
        })}
        {values.units.length < TOTAL_UNITS_MAX &&
         !(values.asset_type === 'residential' && values.residential_type === 'single_family') ? (
          <button className="btn btn-block btn-outline-secondary" type="button" onClick={addUnit}>
            Add Unit
          </button>
        ) : null}
      </Col>
    );
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">General details</h2>
      <Form.Row>
        <CustomSelect
          label="Select a property account"
          name="sub_account"
          options={subAccountsOptions}
          formGroupProps={{ as: Col, md: 6 }}
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="name"
          type="text"
          label="Name of the property"
          placeholder="Name of the property"
          required
        />
      </Form.Row>

      <Form.Row>
        <CustomSelect
          label="Asset type"
          name="asset_type"
          onBlur={handleChangeAssetType}
          options={ASSET_TYPES}
          formGroupProps={{ as: Col, md: 6 }}
          required
        />

        {values.asset_type === 'residential' ?  (
          <CustomSelect
            label="Type"
            name="residential_type"
            options={RESIDENTIAL_TYPES}
            formGroupProps={{ as: Col, md: 6 }}
            required
          />
        ) : null}
      </Form.Row>

      <Form.Row>
        <CustomSelect
          label="Occupancy"
          name="occupancy"
          options={OCCUPANCIES}
          formGroupProps={{ as: Col, md: 6 }}
          required
        />
      </Form.Row>

      <Form.Row>
        {values.asset_type === 'vacant_land' ?  (
          <TextField
            as="textarea"
            rows={1}
            formGroupProps={{ as: Col, md: 6 }}
            name="land_description"
            type="text"
            label="Vacant land general description"
            placeholder="Description"
          />
        ) : null}
      </Form.Row>

      <Form.Row>
        {values.asset_type !== 'vacant_land' ? (
          <CustomSelect
            label="Year built"
            name="built_in"
            options={YEAR_OPTIONS.map(state => ({ value: state }))}
            formGroupProps={{ as: Col, xs: 6, md: 3 }}
            required
          />
        ) : null }
        <TextField
          formGroupProps={{ as: Col, xs: 6, md: 3 }}
          name="area"
          type="number"
          label="Total area, sq ft"
          placeholder="Area, sq ft"
          min={0}
          step={0.1}
          required
        />
      </Form.Row>

      <Form.Row>
        {values.asset_type === 'vacant_land' ? (
          <TextField
            formGroupProps={{ as: Col, md: 6 }}
            name="total_units"
            label="Total units"
            placeholder="Total units"
            type="number"
            onBlur={handleChangeUnits}
            format={RegExp(/\d/g)}
            min={0}
            step={1}
            required
          />
        ) : (
          <FieldArray name="units" render={renderUnits} />
        )}
      </Form.Row>

      <h2 className="h-secondary-font mb-2 pt-3">Address</h2>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="street"
          type="text"
          label="Street address"
          placeholder="Street"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 12, xl: 6 }}
          name="city"
          type="text"
          label="City"
          placeholder="City"
          required
        />

        <CustomSelect
          label="State"
          name="state"
          options={USA_STATES}
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          required
        />

        <TextField
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          name="postal_code"
          label="Zip / Postal code"
          placeholder="ZIP"
          type="text"
          format={RegExp(/\d/g)}
          required
        />
      </Form.Row>

      <h2 className="h-secondary-font mb-2 pt-3">Features</h2>

      <Form.Row>
        {Object.keys(FEATURES).map(key =>
          <Col xs={6} key={key}>
            <Form.Check
              name="features"
              id={key}
              label={key}
              className='mb-3'
              type="checkbox"
              checked={values.features[key]?.toString() === 'true'}
              onChange={handleChangeFeatures}
              custom
            />
          </Col>
        )}
      </Form.Row>
    </>
  );
};

GeneralTab.propTypes = {
  fetchSubAccounts: PropTypes.func
};

GeneralTab.defaultProps = {
  fetchSubAccounts: () => api.getSubAccounts({ status_eq: 1 })
};

export default GeneralTab;
