import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import classnames from 'classnames';
import { withFormik } from 'formik';

import CheckBox from 'components/helpers/CheckBox';
import TermsAndConditionsLabel from 'components/helpers/TermsAndConditionsLabel';
import useBeforeUnload from 'components/helpers/useBeforeUnload';
import { stepsFor, componentsFor } from '../pt-contact-form/tabs';
import api from 'api';
import StepsForm from '../pt-contact-form/steps-form';
import AgreementPreviewModal from './modals/agreement_preview_modal';

import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const STEPS = {
  natural_person: ['personal', 'address', 'verification'],
  company: ['companyInfo', 'address', 'companyVerification', 'questionnaire', 'relatedContacts'],
  edit_company: ['companyInfo', 'address', 'companyVerification', 'relatedContacts'],
  approved_company: ['relatedContacts']
};

const PtSubAccountForm = ({ sub_account, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting, setFieldValue } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);

  const { steps, components } = useMemo(() => {
    let formType = sub_account.attributes.type;
    if (sub_account.attributes.type === 'company' && sub_account.attributes.owner_contact?.id) {
      if (sub_account.attributes.owner_contact.identity_confirmed ||
          sub_account.attributes.owner_contact.status === 'pending') {
        formType = 'approved_company';
      } else formType = 'edit_company';
    }

    return {
      steps: stepsFor(STEPS[formType]),
      components: componentsFor(STEPS[formType])
    };
  }, [sub_account]);

  const [tabsList, setVisited] = useState(sub_account.id ?
    steps.map(i => i.target) : steps.slice(0, 1).map(i => i.target));
  const isSubmitVisible = useMemo(() => tabsList.length === steps.map(i => i.target).length, [tabsList, steps]);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const [showAgreement, setShowAgreement] = useState(false);
  const [agreementContent, setAgreementContent] = useState('');
  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const setVisitedTab = e => {
    const visited = e.target.dataset.visited;
    tabsList.push(visited);
    setVisited([...tabsList]);
  };

  const isVisited = (tab) => {
    if (sub_account.id) { return true; }

    return tabsList.some(item => item === tab);
  };

  const submitForm = () => {
    setSubmitting(true);
    setShowAgreement(false);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.postPtSubAccount(sub_account.id, serialize(values)).
      then(() => window.location.href = '/profile/pt_sub_accounts').
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (sub_account.id) {
      submitForm();
      return;
    }
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.getPrimeTrustAgreementPreview(serialize(values)).
      then(({ data : { content } }) => {
        setAgreementContent(content);
        setFieldValue('agreement', { content: content, accepted: true });
        setShowAgreement(true);
      }).
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    setShowAgreement(false);
    setFieldValue('terms', false);
    if (data.errors) {
      const tabs = steps.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else if (Object.keys(data.errors).includes('terms')) {
        window.scrollTo(0, document.body.scrollHeight);
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const handleCancel = () => window.location.href = '/profile/pt_sub_accounts';

  const serialize = () => {
    const owner_contact_attributes = sub_account.attributes.type === 'natural_person' ?
      {} : {
        beneficial_owners: null,
        beneficial_owners_attributes: values.owner_contact.beneficial_owners?.filter(i => i.changed),
        company_documents: values.owner_contact.company_documents,
      };

    return {
      sub_account: {
        ...values,
        status: 'pending',
        primetrust: true,
        owner_contact: null,
        owner_contact_attributes: {
          ...values.owner_contact,
          ...owner_contact_attributes,
          status: 'pending',
        },
        agreement_attributes: values.agreement,
        questionnaire: null,
        questionnaire_attributes: values.questionnaire,
      },
    };
  };

  const humanizedType = () => sub_account.attributes.type === 'company' ? 'company' : 'individual';

  return (
    <div className="steps_form">
      {isLoading && (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="ml-auto mr-lg-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">
            {sub_account.id ? `Edit ${humanizedType()} a property account` : `Add ${humanizedType()} a property account`}
          </h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {steps.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link',
                  isVisited(item.target) ? 'visited' : '',
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={isVisited(item.target) ? `#${item.target}`: ''}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div
            className={classnames(
              'steps_form__sections-wrapper pb-5',
              sub_account.id ? 'steps_form__sections-wrapper--edit' : '',
            )}
          >
            <StepsForm
              components={components}
              onChangeTab={setVisitedTab}
              visitedTabs={tabsList}
              onCancel={handleCancel}
              localValues={values.owner_contact}
              fieldPrefix="owner_contact."
            />
            <AgreementPreviewModal
              show={showAgreement}
              content={agreementContent}
              onAccept={submitForm}
              onDismiss={() => setShowAgreement(false)}
            />
          </div>
          {isSubmitVisible && (
            <>
              <CheckBox
                name="terms"
                label={<TermsAndConditionsLabel />}
              />
              {sub_account.id ? (
                <div className="form-actions-edit py-4 row">
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={values.terms !== true || isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="form-actions mt-4">
                  <div className="d-sm-inline-block mb-3 mr-3">
                    <button
                      className="btn form-btn-fill form-button"
                      type="button"
                      onClick={handleSubmit}
                      disabled={values.terms !== true || isLoading}
                    >
                      Save
                    </button>
                  </div>
                  <div className="d-sm-inline-block mb-3">
                    <button
                      className="btn form-btn-outline"
                      type="button"
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

PtSubAccountForm.propTypes = {
  sub_account: PropTypes.object,
};
PtSubAccountForm.defaultProps = {
  sub_account: {}
};

export default withFormik({
  mapPropsToValues: ({ sub_account }) => {
    return Object.entries({ ...sub_account.attributes }).
      reduce((acc, [key, value]) => {
        if (value instanceof Object && value instanceof Array === false) {
          acc[key] = {};
          Object.entries(value).forEach(([k, v]) => acc[key][k] = v === null ? '' : v);
          return acc;
        }
        acc[key] = value === null ? '' : value;
        return acc;
      }, {
        terms: 0
      });
  },
})(PtSubAccountForm);
