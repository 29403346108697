import React, { useState } from 'react';

const Business = () => {
  const [show, toggleShow] = useState(false);
  return (
    <div>
      <p>
        <strong>BUSINESS DOCUMENT</strong>
        <br />
        <a href="#" onClick={() => toggleShow(!show)}>{show ? 'hide more' : 'show more'}</a>
      </p>
      {show ? (
        <>
          <p>Documents that are used to help identify a business are specified as documentType other.
          Business Identifying documents we recommend uploading can include the following:</p>

          <ul>
            <li>Limited Liability Corporation (LLC), Corporation: EIN Letter (IRS-issued SS4 confirmation letter).</li>
          </ul>

          <p>Other business documents may be acceptable on a case by case basis with Dwolla approval.
          These include any US government entity (federal, state, local) issued business formation or licensing
          exhibiting the name of the business enrolling with Dwolla, or; Any business formation documents exhibiting
          the name of the business entity in addition to being filed and stamped by a US government entity. Examples include:</p>

          <ul>
            <li>Filed and stamped Articles of Organization or Incorporation</li>
            <li>Sales/Use Tax License</li>
            <li>Business License</li>
            <li>Certificate of Good Standing</li>
          </ul>
        </>
      ) : null}
    </div>
  );
};

export default Business;
