import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePlaidLink } from 'react-plaid-link';
import { PLAID_ENV } from 'globals';

const PlaidLink = ({ token, onReady, onSuccess, onExit }) => {
  const config = {
    onSuccess: onSuccess,
    onExit: onExit,
    // onEvent: (eventName,) => { if(eventName === 'OPEN') onReady(); },
    token: token,
    env: PLAID_ENV,
    receivedRedirectUri: null
  };
  const { open, ready } = usePlaidLink(config);
  useEffect(() => {
    if (!ready) {
      return;
    }
    open();
    onReady();
  }, [ready, open]);

  // don't render anything, just open Link
  return null;
};

PlaidLink.propTypes = {
  token: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  onReady: PropTypes.func.isRequired
};
PlaidLink.defaultProps = {
};

export default PlaidLink;
