import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { useFormikContext } from 'formik';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';
import { resetApiError } from 'stores/global-store';

import {
  USA_STATES,
  COUNTRIES,
} from 'globals/constants.js.erb';

const InternationalBankForm = () => {
  const { values, setFieldValue } = useFormikContext();

  const onCountryChange = (event) => {
    if (event.target.value === values.intermediary_address.country) return;

    setFieldValue('intermediary_address.country', event.target.value);
    resetApiError('intermediary_address.country');

    if (values.intermediary_address.country === 'US' || event.target.value === 'US') {
      setFieldValue('intermediary_address.region', '');
      resetApiError('intermediary_address.region');
    }
  };

  const renderStateOrRegion = () => {
    if (values.intermediary_address.country === 'US') {
      return (
        <CustomSelect
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          label="State"
          name="intermediary_address.region"
          placeholder="State"
          options={USA_STATES}
          required
        />
      );
    }
    return (
      <TextField
        formGroupProps={{ as: Col, sm: 6, xl: 3 }}
        name="intermediary_address.region"
        type="text"
        label="Region"
        placeholder="Region"
      />
    );
  };

  const renderAddress = () => {
    return (
      <div>
        <h2 className="h-secondary-font mb-2 pt-3">Intermediary Bank Address</h2>
        <Form.Row>
          <TextField
            formGroupProps={{ as: Col, md: 6 }}
            name="intermediary_address.street"
            type="text"
            label="Street address"
            placeholder="Street"
            required
          />
        </Form.Row>

        <Form.Row>
          <TextField
            formGroupProps={{ as: Col, md: 6 }}
            name="intermediary_address.city"
            type="text"
            label="City"
            placeholder="City"
            required
          />
        </Form.Row>

        <Form.Row>
          <CustomSelect
            formGroupProps={{ as: Col, md: 6 }}
            label="Country"
            name="intermediary_address.country"
            placeholder="Country"
            options={COUNTRIES}
            onChange={onCountryChange}
            required
          />
          {renderStateOrRegion()}
        </Form.Row>

        <Form.Row>
          <TextField
            formGroupProps={{ as: Col, md: 3 }}
            name="intermediary_address.postal_code"
            type="text"
            label="Zip / Postal code"
            placeholder="ZIP"
            format={RegExp(/[\d,-]/g)}
            required={values.intermediary_address.country === 'US'}
          />
        </Form.Row>
      </div>
    );
  };

  return (
    <>
      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="name"
          type="text"
          label="Bank account name"
          placeholder="Bank account name"
          required
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="iban"
          label="Bank account number"
          placeholder="Bank account number"
          className="text-uppercase"
          type="text"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="bank_name"
          label="Bank name"
          placeholder="Bank name"
          type="text"
          required
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="swift"
          label="Swift code"
          placeholder="Swift code"
          className="text-uppercase"
          type="text"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="intermediary_bank_name"
          label="Intermediary bank name"
          placeholder="Intermediary bank name"
          type="text"
          required
        />
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="intermediary_bank_iban"
          label="Intermediary bank account number"
          placeholder="Intermediary bank account number"
          className="text-uppercase"
          type="text"
          required
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="intermediary_bank_swift_code"
          label="Intermediary bank swift code"
          placeholder="Intermediary bank swift code"
          className="text-uppercase"
          type="text"
          required
        />
      </Form.Row>

      {renderAddress()}
    </>
  );
};

export default InternationalBankForm;
