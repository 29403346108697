import GeneralTab from './GeneralTab';
import NeighborhoodTab from './NeighborhoodTab';
import FinancialTab from './FinancialTab';
import ConditionsTab from './ConditionsTab';
import VisualsTab from './VisualsTab';
import DocumentsTab from './DocumentsTab';
import OtherTab from './OtherTab';

export const TabLinks = [
  {
    target: 'general',
    title: 'General',
    fields: [
      'sub_account',
      'name',
      'asset_type',
      'residential_type',
      'occupancy',
      'land_description',
      'built_in',
      'total_units',
      /units\[\d+\].beds/,
      'area',
      'street',
      'city',
      'state',
      'postal_code',
    ],
  },
  {
    target: 'neighborhood',
    title: 'Neighborhood',
    fields: [
      'schools',
      'crime_rates',
      'air_quality',
      'attractions',
      'parks',
      'amusement',
      'cultural_sites',
      'train',
      'subway',
      'bus',
    ]
  },
  {
    target: 'financial',
    title: 'Financials',
    fields: [
      /^price$/,
      'transfer_taxes',
      'other_costs',
      /units\[\d+\].annual_revenue/,
      /units\[\d+\].annual_revenue_file/,
      'utilities_yr_exps',
      'utilities_yr_exps_file',
      'admin_pro_yr_exps',
      'admin_pro_yr_exps_file',
      'association_yr_fee',
      'association_yr_fee_file',
      'maintenance_yr_exps',
      'maintenance_yr_exps_file',
      'other_yr_exps',
      'other_yr_exps_file',
      'tax_parcel_id',
      'taxes_yr_payments',
      'taxes_yr_payments_file',
      'remaining_months_on_lease',
      'current_appraisals',
      'current_appraisals_file',
      'rehab_cost',
      'price_after_rehab',
      'owner_statement_file',
      'title_report_file',
      'other_reports_file',
    ],
  },
  {
    target: 'conditions',
    title: 'Conditions',
    fields: [
      'condition',
      'survey',
      'survey_file',
      'eng_studies',
      'eng_studies_file',
      'env_studies',
      'env_studies_file',
      'roof_improvements',
      'roof_improvements_file',
      'windows_improvements',
      'windows_improvements_file',
      'heating_cooling_improvements',
      'heating_cooling_improvements_file',
    ],
  },
  {
    target: 'visuals',
    title: 'Visuals',
    fields: [
      'image',
      'rendering_file',
      'floorplan_file',
      'drawing_file',
      'elevations_file',
    ],
  },
  {
    target: 'documents',
    title: 'Documents',
    fields: [
      'title_doc_file',
      'guaranty_file',
      'warranty_file',
      'insurance_doc_file',
    ],
  },
  {
    target: 'other',
    title: 'Other',
    fields: [
      'mortgage',
    ],
  },
];

const Tabs = {
  general: GeneralTab,
  neighborhood: NeighborhoodTab,
  financial: FinancialTab,
  conditions: ConditionsTab,
  visuals: VisualsTab,
  documents: DocumentsTab,
  other: OtherTab,
};

export default Tabs;
