import PropertyForm from "./property-form";
import AdminPropertyForm from "./admin/property-form";
import PtSubAccountForm from "./pt-sub-account-form";
import PtContactForm from "./pt-contact-form";
import PtBankAccountForm from "./pt-bank-account-form";
import SubAccountForm from "./dwolla-v2/sub-account-form";
import BeneficialOwnerForm from "./dwolla-v2/beneficial-owner-form";
import VerificationDocs from "./dwolla-v2/verification-docs";
import BankAccountLink from "./dwolla-v2/bank-account-link";
import BankAccountEdit from "./dwolla-v2/bank-account-edit";
import DepositForm from "./dwolla-v2/deposit-form";
import WithdrawForm from "./dwolla-v2/withdraw-form";
import SubAccountList from './sub-account-list/index';
import SubAccountDetails from "./sub-account-details";

const components = {
  PropertyForm,
  AdminPropertyForm,
  PtSubAccountForm,
  PtContactForm,
  PtBankAccountForm,
  SubAccountForm,
  VerificationDocs,
  BeneficialOwnerForm,
  BankAccountLink,
  BankAccountEdit,
  DepositForm,
  WithdrawForm,
  SubAccountList,
  SubAccountDetails
};

export default components;
