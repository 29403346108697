import axios from "axios";
import qs from 'qs';

const api = axios.create({
  headers: {
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
  timeout: 30000,
  xsrfCookieName: '_simkey_csrf_token',
  xsrfHeaderName: 'X-CSRF-Token',
  withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});
api.interceptors.response.use(
  res => res,
  error => {
    if (error.code === 'ECONNABORTED' || !error.response) {
      Object.assign(error, { response: { statusText: 'Network timeout error' } });
    }
    throw error;
  }
);

export { api };
