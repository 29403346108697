import PersonalTab from './PersonalTab';
import CompanyInfoTab from './CompanyInfoTab';
import CompanyVerificationTab from './CompanyVerificationTab';
import AddressTab from './AddressTab';
import VerificationTab from './VerificationTab';
import QuestionnaireTab from './QuestionnaireTab';
import RelatedContactsTab from './RelatedContactsTab';

const TabLinks = [
  {
    target: 'personal',
    title: 'Personal details',
    fields: [
      /\.{0,1}first_name$/,
      /\.{0,1}middle_name$/,
      /\.{0,1}last_name$/,
      /\.{0,1}email$/,
      /\.{0,1}phone_number$/,
      /\.{0,1}date_of_birth$/,
    ]
  },
  {
    target: 'companyInfo',
    title: 'Company details',
    fields: [
      /\.{0,1}first_name$/,
      /\.{0,1}email$/,
      /\.{0,1}phone_number$/,
      /\.{0,1}description_of_services$/,
      /\.{0,1}jurisdictions_of_business_activity$/,
    ]
  },
  {
    target: 'address',
    title: 'Address',
    fields: [
      /\.{0,1}street$/,
      /\.{0,1}city$/,
      /\.{0,1}country$/,
      /\.{0,1}region$/,
      /\.{0,1}postal_code$/,
    ]
  },
  {
    target: 'verification',
    title: 'ID verification details',
    fields: [
      /\.{0,1}tax_country$/,
      /\.{0,1}tax_state$/,
      /\.{0,1}ssn$/,
      /\.{0,1}date_of_birth$/,
      /\.{0,1}document_type$/,
      /\.{0,1}other_type$/,
      /\.{0,1}document_expires_on$/,
      /\.{0,1}identity_document$/,
      /\.{0,1}identity_document_back$/,
      /\.{0,1}proof_of_address$/,
    ]
  },
  {
    target: 'companyVerification',
    title: 'Company verification details',
    fields: [
      /\.{0,1}tax_country$/,
      /\.{0,1}tax_state$/,
      /\.{0,1}ssn$/,
      /\.{0,1}region_of_formation$/,
      /\.{0,1}date_of_birth$/,
      /\.{0,1}document_type$/,
      /\.{0,1}other_type$/,
      /\.{0,1}document_expires_on$/,
      /\.{0,1}identity_document$/,
      /\.{0,1}identity_document_back$/,
      /\.{0,1}proof_of_address$/,
      /\.{0,1}company_documents$/,
    ]
  },
  {
    target: 'questionnaire',
    title: 'Questionnaire',
    fields: [
      /^questionnaire.source_of_assets_and_income$/,
      /^questionnaire.anticipated_monthly_cash_volume$/,
      /^questionnaire.anticipated_monthly_transactions_incoming$/,
      /^questionnaire.anticipated_monthly_transactions_outgoing$/,
    ]
  },
  {
    target: 'relatedContacts',
    title: 'Related contacts',
    fields: ['beneficial_owners']
  },
];

export function stepsFor(steps, prefix) {
  let tabLinks = TabLinks.filter(tab => steps.includes(tab.target));
  if (prefix) {
    tabLinks = tabLinks.map(s => ({ ...s, target: `${prefix}${s.target}` }));
  }
  if (prefix === 'relatedContact.') {
    tabLinks = tabLinks.filter(tab => tab.target !== 'relatedContact.relatedContacts');
  }
  return tabLinks;
}

export function componentsFor(steps, prefix) {
  const components = TabLinks.reduce((acc, tab) => {
    if (steps.includes(tab.target)) {
      let id = tab.target;
      if (prefix) id = `${prefix}${id}`;
      acc[id] = Tabs[tab.target];
    }
    return acc;
  }, {});
  if (prefix === 'relatedContact.') delete components['relatedContact.relatedContacts'];
  return components;
}

const Tabs = {
  personal: PersonalTab,
  companyInfo: CompanyInfoTab,
  companyVerification: CompanyVerificationTab,
  address: AddressTab,
  questionnaire: QuestionnaireTab,
  verification: VerificationTab,
  relatedContacts: RelatedContactsTab,
};

export default Tabs;
