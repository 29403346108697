import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Beneficial } from '../Beneficial';
import { Customer } from '../Customer';
import { Contacts } from '../Contacts';
import PropTypes from 'prop-types';

const GeneralTab = ({ attributes, accountId }) => {
  const { type, owner_contact } = attributes;
  const { beneficial_owners, controller } = attributes.owner_contact;

  return (
    <>
      <Customer type={type} owner_contact={owner_contact} accountId={accountId} />
      <Contacts owner_contact={owner_contact} accountId={accountId} />
      {type === 'company' ? (
        <>
          <div className="sub-account-details__base-info">
            <h2 className="sub-account-details__base-info__title">Controller</h2>
            <Beneficial contact={controller} />
          </div>
          {!!beneficial_owners?.length && (
            <div className="sub-account-details__base-info">
              <h2 className="sub-account-details__base-info__title">Ownership</h2>
                {/* <button className="px-4 mr-3 mr-md-0 btn form-btn-outline sub-accounts-list__item-edit">
                  Add Beneficial Owner
                </button> */}
                <Row>
                  {beneficial_owners.map((beneficial_owner) => (
                    <Col className="mb-4" md={6} xs={12} key={beneficial_owner.id}>
                      <Beneficial contact={beneficial_owner} />
                    </Col>
                  ))}
                </Row>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

GeneralTab.propTypes = {
  accountId: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    type: PropTypes.string,
    owner_contact: PropTypes.shape({
      beneficial_owners: PropTypes.array,
      controller: PropTypes.object,
    }).isRequired,
  }).isRequired,
};

export default GeneralTab;
