import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['link', 'buyerId'];

  updateLink() {
    const buyerId = this.buyerIdTargets.find((e) => e.checked).value;
    this.linkTargets.forEach(link =>
      link.href = link.href.replace(/(buyer_sub_account_id=)\d*/ig, '$1'+ buyerId)
    );
  }
}
