import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import TextField from 'components/helpers/TextField';
import FileField from 'components/helpers/FileField';
import CustomSelect from 'components/helpers/CustomSelect';

import { CONDITIONS } from 'globals/constants.js.erb';

const ConditionsTab = () => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Physical conditions</h2>

    <Form.Row>
      <CustomSelect
        label="Condition"
        name="condition"
        options={CONDITIONS}
        formGroupProps={{ as: Col, md: 6 }}
        required
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="survey"
        type="text"
        label="Survey"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="survey_file"
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="eng_studies"
        type="text"
        label="Engineering studies"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="eng_studies_file"
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="env_studies"
        type="text"
        label="Environmental studies"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="env_studies_file"
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="roof_improvements"
        type="text"
        label="Recent improvements - roof"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="roof_improvements_file"
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="windows_improvements"
        type="text"
        label="Recent improvements - windows"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="windows_improvements_file"
      />
    </Form.Row>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, sm: 6 }}
        name="heating_cooling_improvements"
        type="text"
        label="Recent improvements - heating / cooling"
        placeholder="Comment"
      />

      <FileField
        formGroupProps={{ as: Col, xs:12, sm: 6 }}
        name="heating_cooling_improvements_file"
      />
    </Form.Row>
  </>
);

export default ConditionsTab;
