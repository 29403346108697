import React from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FieldArray, useFormikContext } from 'formik';

import TextField from 'components/helpers/TextField';
import FileField from 'components/helpers/FileField';
import {
  LISTING_FEE,
  SELLING_FEE,
} from 'globals/constants.js.erb';

const FinancialTab = () => {
  const { values } = useFormikContext();
  const renderUnits = () => {
    return (
      <>
        {values.units.map((unit, index) => {
          const label = `Unit ${index + 1} revenue per annum, $`;
          return (
            <Form.Row key={`units.${index}`}>
              <TextField
                formGroupProps={{ as: Col, sm: 6 }}
                name={`units[${index}].annual_revenue`}
                label={label}
                tooltip="Attach revenue confirmation"
                placeholder="999999999.99"
                type="number"
                min={1}
                max={999999999.99}
                step={0.1}
                required
              />

              <FileField
                formGroupProps={{ as: Col, xs:12, sm: 6 }}
                name={`units[${index}].annual_revenue_file`}
                relationName="annual_revenue_file"
              />
            </Form.Row>
          );})}
      </>
    );
  };

  /* eslint-disable no-magic-numbers */
  const totalPrice = () => {
    // property price - transfer taxes - other costs - $150 - 1.25% * property price.
    const transaction_taxes_value = values.price / 100 * values.transfer_taxes;
    const gross = [values.price, transaction_taxes_value, values.other_costs].reduce((a, b) => {
      return parseFloat(a || 0, 10) - parseFloat(b || 0, 10);
    });
    const total = gross - LISTING_FEE - values.price * SELLING_FEE;
    const result = Math.round(total * 100 + Number.EPSILON) / 100;
    return result > 0 ? result : 0;
  };
  /* eslint-enable no-magic-numbers */

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Financials</h2>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 12, xl: 6 }}
          name="price"
          type="number"
          label="Selling price, $"
          placeholder="999999.99"
          min={1}
          max={999999.99}
          step={0.1}
          required
        />

        <TextField
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          name="transfer_taxes"
          type="number"
          label="Transfer taxes, %"
          placeholder="99.99"
          min={0}
          max={100}
          step={0.1}
          required
        />

        <TextField
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          name="other_costs"
          type="number"
          label="Other costs, $"
          placeholder="999999.99"
          min={1}
          max={999999.99}
          step={0.1}
        />

        <Col md={6}>
          <div>Total price, $</div>
          <span className="total-price total-price--empty">{totalPrice().toLocaleString()}</span>
          <Form.Text className="text-muted">
            The approximate amount of money that you will receive after all taxes and fees have been subtracted.
          </Form.Text>
        </Col>
      </Form.Row>

      <FieldArray name="units" render={renderUnits} />

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="utilities_yr_exps"
          label="Utilities per annum, $"
          tooltip="Attach Utilities confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="utilities_yr_exps_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="admin_pro_yr_exps"
          label="Administrative & Professional expenses per annum, $"
          tooltip="Attach Administrative & Professional expenses confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="admin_pro_yr_exps_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="association_yr_fee"
          label="Association fee per annum, $"
          tooltip="Attach Association fee confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="association_yr_fee_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="maintenance_yr_exps"
          label="Maintenance per annum, $"
          tooltip="Attach Maintenance confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="maintenance_yr_exps_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="other_yr_exps"
          label="Other expenses per annum, $"
          tooltip="Attach Other expenses confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="other_yr_exps_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="tax_parcel_id"
          label="Tax Parcel Identification"
          placeholder="Identificator"
          type="text"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="taxes_yr_payments"
          label="Property taxes per annum, $"
          tooltip="Attach Property taxes confirmation"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="taxes_yr_payments_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="remaining_months_on_lease"
          label="Remaining months on lease"
          placeholder="999"
          type="number"
          format={RegExp(/\d/g)}
          min={1}
          max={999}
          step={1}
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, sm: 6 }}
          name="current_appraisals"
          label="Current estimates / appraisals value, $"
          tooltip="Attach Current estimated values/appraisals"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
          required
        />

        <FileField
          formGroupProps={{ as: Col, xs:12, sm: 6 }}
          name="current_appraisals_file"
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="rehab_cost"
          label="Rehab cost, $"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
        />
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name="price_after_rehab"
          label="Price after rehab, $"
          placeholder="999999999.99"
          type="number"
          min={1}
          max={999999999.99}
          step={0.1}
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, xs:12 }}
          name="owner_statement_file"
          label="Owner closing statement and transaction history"
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, xs:12 }}
          name="other_reports_file"
          label="Other contracts/reports"
        />
      </Form.Row>
    </>
  );
};

export default FinancialTab;
