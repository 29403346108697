import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

const StepsForm = ({ onCancel, components, fieldPrefix, onChangeTab, visitedTabs, localValues }) => (
  <Form className="position-relative steps_form" noValidate>
    {useMemo(() => visitedTabs.map((tab, index) => {
      const ComponentTab = components[tab];
      const nextTab = Object.keys(components)[index + 1];
      const isLast = nextTab && index === visitedTabs.length - 1;
      const styles = isLast ?
        { minHeight: (document.querySelector('.modal-body')?.offsetHeight ?? '100vh') } : {};

      return (
        <div
          key={tab}
          id={tab}
          className='steps_form__section visited'
          style={styles}
        >
          <ComponentTab fieldPrefix={fieldPrefix} localValues={localValues} />

          {isLast && (
            <div className="form-actions mt-4">
              <div className="d-sm-inline-block mb-3 mr-3">
                <a
                  href={`#${nextTab}`}
                  className="btn form-btn-fill form-button"
                  data-visited={nextTab}
                  onClick={onChangeTab}
                >
                  Next
                </a>
              </div>
              <div className="d-sm-inline-block mb-3">
                <button
                  className="btn form-btn-outline"
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      );
    }), [components, visitedTabs, localValues.controller, localValues.beneficial_owners, localValues.country])}
  </Form>
);

StepsForm.propTypes = {
  components: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  visitedTabs: PropTypes.array.isRequired,
  localValues: PropTypes.any.isRequired,
  fieldPrefix: PropTypes.string,
};
StepsForm.defaultProps = {
  fieldPrefix: ''
};

export default StepsForm;
