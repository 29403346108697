import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

import { v4 as uuid } from 'uuid';

import ContactFormModal from '../modals/contact-form';
import ContactCard from '../contacts/card';
import { useFormikContext } from 'formik';
import api from 'api';
import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const ControllerTab = ({ localValues, fieldPrefix }) => {
  const [openModal, setOpenModal] = useState(false);
  const [controller, setController] = useState(localValues.controller);
  const { setFieldValue } = useFormikContext();

  const addContact = (contact) => {
    contact.changed = true;

    setFieldValue(`${fieldPrefix}controller`, contact);
    setOpenModal(false);
    setController(contact);
    resetApiError(`${fieldPrefix}controller`);
  };

  const handleAddContactClick = () => openNewForm({ type: 'Contacts::NaturalPerson' });

  const openEditForm = () => {
    setOpenModal(true);
  };

  const openNewForm = ({ type }) => {
    resetApiError(`${fieldPrefix}controller`);
    GlobalStore.update(s => { s.isLoading = true; });
    api.buildContact({ type }).
      then(({ data: { attributes } }) => {
        setController({ uuid: uuid(), ...attributes });
        setOpenModal(true);
      }).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
      });
  };

  const addPersonButton = () => {
    return (
      <button
        className="btn mr-2 btn-outline-secondary"
        type="button"
        data-contact-type="Contacts::NaturalPerson"
        onClick={handleAddContactClick}
      >
        Add Control Person
      </button>
    );
  };

  const resetBaseApiError = () => resetApiError('base');

  const reset = () => {
    setController(localValues.controller);
    setOpenModal(false);
  };

  const renderTabError = () => {
    const errorMessage = readApiError(`${fieldPrefix}controller`);
    return errorMessage ? (
      <Form.Row>
        <Form.Group as={Col} md={6}>
          <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
    ) : null;
  };

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">Control Person</h2>

      {controller ? (
        <Form.Row className="mb-3">
          <Col md={12}>
            <Accordion>
              <ContactCard contact={controller} onEditClick={openEditForm} />
            </Accordion>
          </Col>
        </Form.Row>
      ) : null}
      {renderTabError()}

      <Form.Row>
        <Form.Group as={Col} md={12}>
          <Form.Text className="text-muted">
            A single individual with significant responsibility to control, manage,
            including an executive officer or senior manager (e.g., a Chief Executive Officer, Chief Financial Officer,
            Managing member, General Partner, President, Vice President, or Treasurer) or any other individual
            who regularly performs similar functions (i.e. the control prong).
          </Form.Text>
        </Form.Group>
      </Form.Row>
      {controller ? null : (
        <Form.Row>
          <Form.Group as={Col} md={12}>
            {addPersonButton()}
          </Form.Group>
        </Form.Row>
      )}
      <ContactFormModal
        contact={controller}
        show={openModal}
        onAccept={addContact}
        onEnter={resetBaseApiError}
        onExit={resetBaseApiError}
        onDismiss={reset}
        formPrefix="controller"
      />
    </>
  );
};

ControllerTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

ControllerTab.defaultProps = {
  fieldPrefix: ''
};

export default ControllerTab;
