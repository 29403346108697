import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['feedback'];

  connect() {
    $(this.element).on('hidden.bs.modal', () => {
      this.feedbackTarget.innerHTML = '';
    });
  }

  fail({ detail: [data, statusMessage] }) {
    this.feedbackTarget.innerHTML = data?.errors?.join('. ') || statusMessage;
  }
  success({ detail: [data] }) {
    window.location.href = data?.redirect_to || window.location.href;
  }
}
