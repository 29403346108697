import { Controller } from "@hotwired/stimulus";

import applyPhoneMask from 'src/utils/apply-phone-mask';
import SVG from 'icons/sprite.svg';

export default class extends Controller {
  static targets = ['terms', 'submitButton', 'input', 'phone'];

  toglleSubmitButton() {
    if (this.termsTarget.checked) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }

  togglePswdInputType() {
    const input = this.inputTargets[0];
    this.toggleInputType(input);
  }

  toggleConfirmPswdInputType() {
    const input = this.inputTargets[1];
    this.toggleInputType(input);
  }

  toggleInputType = (input) => {
    if (input.type === 'password') {
      input.type = 'text';
      this.toggleSVG(input, 'eye-show');
    } else {
      input.type = 'password';
      this.toggleSVG(input, 'eye-hide');
    }
  }

  toggleSVG = (input, name) => {
    const useElem = input.nextSibling.querySelector('.btn svg use');
    useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'href', `${SVG}#${name}`);
  }

  applyPhoneMask = () => {
    this.phoneTarget.value = applyPhoneMask(this.phoneTarget.value);
  }
}
