import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import { withFormik } from 'formik';

import useBeforeUnload from 'components/helpers/useBeforeUnload';
import Tabs, { TabLinks } from 'components/property-form/tabs';
import api from 'api';
import GlobalStore, { readApiError, resetApiError } from 'stores/global-store';

const PropertyForm = ({ property, ...formikBag }) => {
  const { values, status, dirty, setStatus, setSubmitting, isSubmitting } = formikBag;
  useBeforeUnload(dirty && !isSubmitting);
  const [invalidTabs, setInvalidTabs] = useState([]);
  const isLoading = GlobalStore.useState(s => s.isLoading);

  const redirectToPath = `/admin/properties/${property.id}`;

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    GlobalStore.update(s => { s.isLoading = true; });
    resetApiError('base');
    api.putAdminProperty(property.id, serialize()).
      then(() => window.location.href = redirectToPath).
      catch(handleError).
      finally(() => {
        GlobalStore.update(s => { s.isLoading = false; } );
        setSubmitting(false);
      });
  };

  const handleError = ({ response, response: { data } })=> {
    setStatus('inValid');
    if (data.errors) {
      const tabs = TabLinks.map(tab => {
        if (Object.keys(data.errors).filter(element => tab.fields.find(field => element.match(field))).length > 0) {
          return tab.target;
        }
      }).filter(e => e);
      setInvalidTabs(tabs);
      GlobalStore.update(s => { s.apiErrors = data.errors; });
      if (tabs.length) {
        window.location.href = `#${tabs[0]}`;
      } else { window.scrollTo(0, 0); }
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
      window.scrollTo(0, 0);
    }
  };

  const serialize = () => {
    const units = values.asset_type === 'vacant_land' ?
      values.units.map(unit => ({ ...unit, beds:  '' })) : values.units;

    return {
      property: {
        ...values,
        sub_account: null,
        units: null,
        sub_account_id: values.sub_account,
        residential_type: values.asset_type === 'residential' ? values.residential_type : '',
        land_description: values.asset_type === 'vacant_land' ? values.land_description : '',
        built_in: values.asset_type === 'vacant_land' ? '' : values.built_in,
        units_attributes: [...values.units_attributes, ...units],
        total_units: values.asset_type === 'vacant_land' ? values.total_units : values.units.length,
        images: values.images.map((image, i) => ({ ...image, position: i + 1 })),
      }
    };
  };

  const renderTabs = (tab) => {
    const ComponentTab = Tabs[tab];
    let componentProps;
    if (tab === 'general') {
      componentProps = {
        fetchSubAccounts: (values) => api.getAdminSubAccounts({ status_eq: 1, user_id_eq: values?.user?.id })
      };
    }
    return <div key={tab} id={tab} className="steps_form__section visited"><ComponentTab {...componentProps}/></div>;
  };

  const apiError = readApiError('base');

  return (
    <Form className="position-relative" noValidate onSubmit={onSubmit}>
      {isLoading &&
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}
      <Row className="steps_form__heading">
        <Col md={8} lg={6} className="mx-auto">
          <h1 className="steps_form__heading-title pt-4 mt-4">Add piece of property</h1>
          {apiError ?
            <div className="invalid-feedback d-block">{apiError}</div> : null}
        </Col>
      </Row>
      <Row>
        <Col md={4} lg={3} className="steps_form__tabs pb-3">
          <Nav
            id="steps_form"
            className={[
              "steps_form__tabs-nav sticky-top pt-3",
              status === 'inValid' ? 'steps_form__tabs-nav--is-invalid' : ''
            ]}
          >
            {TabLinks.map((item, index) =>
              <Nav.Link
                key={`${item.target}_${index}`}
                className={[
                  'steps_form__tabs-link visited',
                  invalidTabs.includes(item.target) ? 'steps_form__tabs-link--is-invalid' : ''
                ]}
                href={`#${item.target}`}
              >
                {item.title}
              </Nav.Link>
            )}
          </Nav>
        </Col>
        <Col md={8} lg={9} xl={6}>
          <div className="steps_form__sections-wrapper pb-5 steps_form__sections-wrapper--edit">
            {Object.keys(Tabs).map(renderTabs)}
          </div>
          <div className="form-actions-edit py-4 row">
            <div className="col-6 col-sm-4 col-lg-3">
              <button
                className="btn btn-dark form-button"
                type="submit"
                disabled={isLoading}
              >
                Save
              </button>
            </div>
            <div className="col-6 col-sm-4 col-lg-3">
              <a
                className="btn btn-outline-dark form-button"
                href={redirectToPath}
              >
                Cancel
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

PropertyForm.propTypes = {
  property: PropTypes.object,
};
PropertyForm.defaultProps = {
  property: {}
};


export default withFormik({
  mapPropsToValues: ({ property: { attributes } }) => {
    return Object.entries(attributes || {}).reduce((acc, [key, value]) => {
      acc[key] = value === null ? '' : value;
      return acc;
    }, {
      sub_account: attributes.sub_account_id || '',
      units: [{ id: '', beds: '', annual_revenue: '' }],
      units_attributes: []
    });
  },
})(PropertyForm);
