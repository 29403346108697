import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { addDays } from 'date-fns';

import TextField from 'components/helpers/TextField';
import CustomSelect from 'components/helpers/CustomSelect';
import FileField from 'components/helpers/FileField';
import CustomDatePicker from 'components/helpers/CustomDatePicker';

import { useFormikContext } from 'formik';

import {
  KYC_DOCUMENT_TYPES,
  USA_STATES,
  COUNTRIES,
} from 'globals/constants.js.erb';

const ACCEPTED_FORMATS = "image/png, image/jpeg, image/jpg, application/pdf";
const ATTACHMENT_TYPE = "Attachments::IdentityDocument";

import { resetApiError } from 'stores/global-store';

const VerificationTab = ({ fieldPrefix, localValues }) => {
  const { setFieldValue } = useFormikContext();

  const onDocumentTypeChange = (event) => {
    if (event.target.value === localValues.document_type) return;

    setFieldValue(`${fieldPrefix}document_type`, event.target.value);
    resetApiError(`${fieldPrefix}identity_document_back`);
  };

  const renderStateOrRegion = () => {
    if (localValues.tax_country === 'US') {
      return (
        <CustomSelect
          formGroupProps={{ as: Col, sm: 6, xl: 3 }}
          label="State"
          name={`${fieldPrefix}tax_state`}
          placeholder="State"
          options={USA_STATES}
          required
        />
      );
    }
    return null;
  };

  const isRequiredBackId = () => ['drivers_license', 'government_id'].includes(localValues.document_type);

  return (
    <>
      <h2 className="h-secondary-font mb-2 pt-3">ID verification details</h2>

      <Form.Row>
        <CustomSelect
          formGroupProps={{ as: Col, md: 6 }}
          label="Tax country"
          name={`${fieldPrefix}tax_country`}
          placeholder="Country of proof of ID"
          options={COUNTRIES}
          required
        />
        {renderStateOrRegion()}
      </Form.Row>

      <Form.Row>
        <TextField
          formGroupProps={{ as: Col, md: 6 }}
          name={`${fieldPrefix}ssn`}
          type="text"
          label="Tax ID number/SSN"
          placeholder="Tax ID Number"
          helpText="Tax ID Number for US citizens or ID Number listed on
                    the uploaded Proof of ID for Non-US citizens"
          required
        />
      </Form.Row>

      <Form.Row>
        <CustomSelect
          formGroupProps={{ as: Col, md: 6 }}
          label="Document type"
          name={`${fieldPrefix}document_type`}
          placeholder="Choose"
          options={KYC_DOCUMENT_TYPES}
          onChange={onDocumentTypeChange}
          required
        />
        {localValues.document_type === 'other' ? (
          <TextField
            formGroupProps={{ as: Col, md: 6 }}
            name={`${fieldPrefix}other_type`}
            type="text"
            label="Other"
            required
          />
        ) : null}
      </Form.Row>

      <Form.Row>
        <Col md={6}>
          <CustomDatePicker
            name={`${fieldPrefix}document_expires_on`}
            placeholder="Document expires on"
            wrapperClassName="d-block"
            minDate={addDays(new Date(), 1)}
            label="Expires on"
          />
        </Col>
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}identity_document`}
          relationName="identity_document"
          label="Valid Proof of Government Issued ID"
          type={ATTACHMENT_TYPE}
          accept={ACCEPTED_FORMATS}
          required
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}identity_document_back`}
          relationName="identity_document_back"
          label="Back side of Valid Proof of Government Issued ID"
          accept={ACCEPTED_FORMATS}
          type={ATTACHMENT_TYPE}
          required={isRequiredBackId()}
        />
      </Form.Row>

      <Form.Row>
        <FileField
          formGroupProps={{ as: Col, md:6 }}
          name={`${fieldPrefix}proof_of_address`}
          relationName="proof_of_address"
          label={
            <Form.Label className="flex-label" title="Proof of Address (valid within the last 90 days)">
              <span className="form-label__text">Proof of Address <br/>(valid within the last 90 days)</span>
            </Form.Label>
          }
          helpText="Required only if the address is not listed on the govt issued ID"
          accept={ACCEPTED_FORMATS}
          type={ATTACHMENT_TYPE}
        />
      </Form.Row>
    </>
  );
};

VerificationTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

VerificationTab.defaultProps = {
  fieldPrefix: ''
};

export default VerificationTab;
