import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import { subYears } from 'date-fns';

import TextField from 'components/helpers/TextField';
import CustomDatePicker from 'components/helpers/CustomDatePicker';

const PersonalTab = ({ fieldPrefix }) => (
  <>
    <h2 className="h-secondary-font mb-2 pt-3">Personal details</h2>

    <Form.Row>
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}first_name`}
        type="text"
        label="First name"
        placeholder="First name"
        required
      />
      <TextField
        formGroupProps={{ as: Col, md: 6 }}
        name={`${fieldPrefix}last_name`}
        type="text"
        label="Last name"
        placeholder="Last name"
        required
      />
    </Form.Row>

    <Form.Row>
      <Col md={6}>
        <CustomDatePicker
          name={`${fieldPrefix}date_of_birth`}
          placeholder="Date of birth"
          wrapperClassName="d-block"
          /* eslint-disable-next-line no-magic-numbers */
          maxDate={subYears(new Date(), 18)}
          label="Date of birth"
          required
        />
      </Col>
    </Form.Row>
  </>
);

PersonalTab.propTypes = {
  fieldPrefix: PropTypes.string,
  localValues: PropTypes.any.isRequired
};

PersonalTab.defaultProps = {
  fieldPrefix: ''
};

export default PersonalTab;
