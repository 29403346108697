import React from 'react';
import PropTypes from 'prop-types';
import BootstrapModal from 'react-bootstrap/Modal';
import { withFormik } from 'formik';

import api from 'api';
import Spinner from 'components/helpers/Spinner';
import Form from './form';

import GlobalStore, { readApiError } from 'stores/global-store';

const Modal = ({ sub_account_id, show, onCancel, ...formikBag }) => {
  const { values, resetForm, setStatus, setFieldValue } = formikBag;

  const isLoading = GlobalStore.useState(s => s.isLoading);
  const apiError = readApiError('base');

  const handleSubmit = () => {
    GlobalStore.update(s => ({ ...s, isLoading: true, apiErrors: {} }));

    api.createWithdraw(sub_account_id, serializedData())
      .then(({ data }) => window.location.href = data.redirect_to)
      .catch(handleError)
      .finally(() => GlobalStore.update(s => { s.isLoading = false; }));
  };

  const handleError = ({ response, response: { data } }) => {
    setStatus('inValid');
    setFieldValue('terms', false);
    if (data.errors) {
      GlobalStore.update(s => { s.apiErrors = data.errors; });
    } else {
      GlobalStore.update(s => { s.apiErrors = { base: [data] || [response.statusText] }; });
    }
    window.scrollTo(0, 0);
  };

  const handleCancel = () => {
    GlobalStore.update(s => { s.apiErrors = {}; });
    resetForm();
    onCancel();
  };

  const serializedData = () => ({
    funds_transfer: values,
  });

  return (
    <BootstrapModal
      backdrop="static"
      keyboard={true}
      size="lg"
      scrollable
      onHide={handleCancel}
      show={show}
    >
      <BootstrapModal.Header>
        <BootstrapModal.Title as="h5">
          Withdraw funds
          <div className="text-muted"></div>
        </BootstrapModal.Title>
      </BootstrapModal.Header>
      <BootstrapModal.Body className="pb-0">
        <Spinner show={isLoading} />
        {apiError ?
          <div className="invalid-feedback d-block">{apiError}</div> : null}
        <Form onSubmit={handleSubmit} onCancel={handleCancel} />
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};


Modal.propTypes = {
  sub_account_id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default withFormik({
  mapPropsToValues: () => ({
    amount: '',
    terms: false
  })
})(Modal);
