import { Store } from "pullstate";

const GlobalStore = new Store({
  isLoading: false,
  apiErrors: {},
});

const readApiError = (name) => {
  return GlobalStore.useState(s => s.apiErrors ? s.apiErrors[name]?.join(' ') : null);
};

const resetApiError = (name) => {
  GlobalStore.update(s => { s.apiErrors = { ...s.apiErrors, [name]: null }; });
};

const setIsLoading = (value) => {
  GlobalStore.update(s => { s.isLoading = value; });
};

const isLoading = () => {
  return GlobalStore.useState(s => s.isLoading);
};

export default GlobalStore;
export { readApiError, resetApiError, isLoading, setIsLoading };
