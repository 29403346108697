import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DepositForm from '../dwolla-v2/deposit-form/index';
import BankAccountLink from '../dwolla-v2/bank-account-link/index';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const SubAccountCard = ({ account: { id, attributes, links } }) => {
  const {
    full_name,
    status,
    status_label,
    balance,
    masked_iban,
    pending_funds,
    debited_funds,
    type_label,
  } = attributes;
  const { details, edit, link_bank_account, deposit, money_transfer } = links;

  return (
    <div className="sub-accounts-list mt-4">
      <div className="sub-accounts-list__item">
        <div className="sub-accounts-list__item-header pb-2">
          <div className="sub-accounts-list__item-type">{type_label}</div>
          <div
            className={classnames(
              'sub-accounts-list__item-status ml-2',
              `sub-accounts-list__item-status--${status}`
            )}>
            {status_label}
          </div>
        </div>

        <Row className="justify-content-between align-items-center pb-2">
          <Col xs={6} md={6}>
            <div className="sub-accounts-list__item-name">{full_name}</div>
          </Col>
          {money_transfer ? (
            <Col xs="auto" className="d-md-none">
							<a
                className="btn form-btn-outline sub-accounts-list__item-edit sub-accounts-list__item-btn"
                href={money_transfer}
              >
                Transfer
              </a>
            </Col>
          ) : null}
          <Col xs={12} md={6} className="text-md-right text-left">
            {masked_iban ? (
              <div className="sub-accounts-list__item-name">{masked_iban}</div>
            ) : null}
          </Col>
        </Row>

        <hr className="sub-accounts-list__line" />

        <Row className="justify-content-between align-items-center pb-2">
          <Col>
            <div className="sub-accounts-list__item-money tex-left">
              {status === "approved" ? balance : `--`}
            </div>
          </Col>

          <Col className="d-flex justify-content-end">
            {money_transfer ? (
              <div className="d-none d-md-block text-right">
                <a
                  className="btn form-btn-outline sub-accounts-list__item-edit sub-accounts-list__item-btn"
                  href={money_transfer}>
                  Transfer
                </a>
              </div>
            ) : null}

            <div className="text-right ml-2">
              {masked_iban ? (
                <DepositForm sub_account_id={id} disabled={!deposit} buttonClass="sub-accounts-list__item-btn" />
              ) : (
                <BankAccountLink link={link_bank_account} />
              )}
            </div>
          </Col>
        </Row>

        <Row className="justify-content-between align-items-center pb-2">
          <Col>
            <div className="d-flex align-items-center justify-content-start">
              <div className="info-column mr-4">
                <div>Pending</div>
                <div className="sub-accounts-list__item-info-font">
                  {status === "approved" ? `+${pending_funds}` : `--`}
                </div>
              </div>

              <div className="info-column">
                <div>Debited</div>
                <div className="sub-accounts-list__item-info-font">
                  {status === "approved" ? `-${debited_funds}` : `--`}
                </div>
              </div>
            </div>
          </Col>

          <Col className="d-flex justify-content-end">
            {details ? (
              <a className="btn form-btn-outline sub-accounts-list__item-btn" href={details}>
                Details
              </a>
            ) : null}
          </Col>
        </Row>

        {status === 'document' ? (
          <div className="sub-accounts-list__item-message sub-accounts-list__item-message--document">
            <div className="sub-accounts-list__item-message__text">
              To verify your Controller ID please attach photo or scans your
              personal documents.
            </div>
          </div>
        ) : null}

        {status === 'pending' ? (
          <div className="sub-accounts-list__item-message sub-accounts-list__item-message--pending">
            <div className="sub-accounts-list__item-message__text">
              The verification procedure may take from a couple of minutes up
              to several days.
            </div>
          </div>
        ) : null}

        {status === 'rejected' ? (
          <div className="sub-accounts-list__item-message sub-accounts-list__item-message--rejected">
            <div className="sub-accounts-list__item-message__text">
              Your sub-account was rejected due to the lack of compliance.
              Edit your sub-account details and submit again or contact
              SimKey.
            </div>
            {edit ?(
              <a className="btn form-btn-outline sub-accounts-list__item-edit sub-accounts-list__item-btn mt-2" href={edit}>
                Edit account
              </a>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubAccountCard;

SubAccountCard.propTypes = {
  account: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      full_name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      status_label: PropTypes.string.isRequired,
      balance: PropTypes.string.isRequired,
      masked_iban: PropTypes.string,
      pending_funds: PropTypes.string.isRequired,
      debited_funds: PropTypes.string.isRequired,
      type_label: PropTypes.string.isRequired,
    }).isRequired,
    links: PropTypes.shape({
			money_transfer: PropTypes.string,
      details: PropTypes.string,
      edit: PropTypes.string,
      link_bank_account: PropTypes.string,
      deposit: PropTypes.string,
    }).isRequired,
  }),
};
