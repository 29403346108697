import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { format } from 'date-fns';
import { DATE_FORMAT } from 'globals/constants.js.erb';

export const FundsTransfers = ({ funds: { attributes } }) => {
  const {
    type_of,
    type_of_label,
    status,
    // id,
    created_at,
    status_label,
    amount,
    incoming,
    account,
  } = attributes;

  const fundsSource = useMemo(() => {
    if (account) return account;

    return type_of === 'fees' ? 'SimKey' : 'Bank account';
  }, [account, type_of]);

  return (
    <div className="funds_transfer-list__item mb-3">
      <div className="funds_transfer-list__col-1">
        {/*<div className="funds_transfer-list__id">{id}</div>*/}
        <div className="funds_transfer-list__name">
          {fundsSource}
        </div>
      </div>
      <div className="funds_transfer-list__col-2">
        <div className="funds_transfer-list__date">{format(Date.parse(created_at), `${DATE_FORMAT} 'at' p`)}</div>
        <div className="funds_transfer-list__type">{type_of_label}</div>
      </div>
      <div className="funds_transfer-list__col-3 text-right">
        <div className="funds_transfer-list__cost">{(incoming ? '+' : '-') + amount}</div>
        <div className="funds_transfer-list__property-status">
          {incoming ? 'Incoming' : 'Outgoing'}
        </div>
        <div
          className={classnames(
            'funds_transfer-list__status',
            `funds_transfer-list__status--${status}`
          )}>
          {status_label}
        </div>
      </div>
    </div>
  );
};

FundsTransfers.propTypes = {
  funds: PropTypes.shape({
    attributes: PropTypes.shape ({
      type_of_label: PropTypes.string,
      status :PropTypes.string,
      id: PropTypes.number,
      created_at: PropTypes.string,
      status_label: PropTypes.string,
      amount: PropTypes.string,
      type_of: PropTypes.string,
      incoming: PropTypes.bool,
      account: PropTypes.string,
    }).isRequired
  }).isRequired,
};

export default FundsTransfers;
